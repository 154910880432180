import { ReactComponent as PhoneConnect } from '../../../public/images/icons_svg/PhoneConnect.svg';
import { ReactComponent as Camera } from '../../../public/images/icons_svg/Achteruitrijcamera.svg';
import { ReactComponent as Trekhaak } from '../../../public/images/icons_svg/Trekhaak.svg';
import { ReactComponent as ClimateControl } from '../../../public/images/icons_svg/ClimateControl.svg';
import { ReactComponent as ZevenZitter } from '../../../public/images/icons_svg/7Zitter.svg';
import { ReactComponent as AdaptiveCruiseControl } from '../../../public/images/icons_svg/AdaptiveCruiseControl.svg';
import { ReactComponent as PDC } from '../../../public/images/icons_svg/PDC.svg';
import { ReactComponent as FourWheelDrive } from '../../../public/images/icons_svg/4x4.svg';
import { ReactComponent as Luchtvering } from '../../../public/images/icons_svg/Luchtvering.svg';
import { ReactComponent as Sportstoelen } from '../../../public/images/icons_svg/Sportstoelen.svg';
import { ReactComponent as VirtualCockpit } from '../../../public/images/icons_svg/VirtualCockpit.svg';
import { ReactComponent as Navigation } from '../../../public/images/icons_svg/navigation.svg';
import { ReactComponent as Airco } from '../../../public/images/icons_svg/airco.svg';
import { ReactComponent as AlloyWheels } from '../../../public/images/icons_svg/alloyWheels.svg';
import { ReactComponent as Leather } from '../../../public/images/icons_svg/leather.svg';
import { ReactComponent as Roof } from '../../../public/images/icons_svg/roof.svg';
import { ReactComponent as Xenon } from '../../../public/images/icons_svg/xenon.svg';
import { ReactComponent as InStock } from '../../../public/images/icons_svg/inStock.svg';
import { ReactComponent as IsDealer } from '../../../public/images/icons_svg/isDealer.svg';
import { ReactComponent as OfficialMake } from '../../../public/images/icons_svg/officialMake.svg';
import { ReactComponent as premiumAudio } from '../../../public/images/icons_svg/premiumAudio.svg';
import { ReactComponent as AMG } from '../../../public/images/icons_svg/amg.svg';
import { ReactComponent as MPakket } from '../../../public/images/icons_svg/m-pakket.svg';
import { ReactComponent as Sline } from '../../../public/images/icons_svg/s-line.svg';
import { ReactComponent as FR } from '../../../public/images/icons_svg/FR.svg';

export const optionIcons = {
  'PhoneConnect': PhoneConnect,
  'Achteruitrijcamera': Camera,
  'Trekhaak': Trekhaak,
  'Climate Control': ClimateControl,
  '7 Zitter': ZevenZitter,
  'Adaptive Cruise Control': AdaptiveCruiseControl,
  'PDC': PDC,
  '4x4': FourWheelDrive,
  'Luchtvering': Luchtvering,
  'Sportstoelen': Sportstoelen,
  'Virtual Cockpit': VirtualCockpit,
  'navigation': Navigation,
  'alloy_wheels': AlloyWheels,
  'leather': Leather,
  'xenon': Xenon,
  'airco': Airco,
  'roof': Roof,
  'is_dealer': IsDealer,
  'official_make': OfficialMake,
  'in_stock': InStock,
  'Premium audio': premiumAudio,
  'AMG pakket': AMG,
  'M pakket': MPakket,
  'S-line': Sline,
  'FR': FR,
};
