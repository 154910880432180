import React from 'react';
import { CustomIconProps } from './custom-icon';
import { optionIcons } from './option-icons-list';
import { ClickState } from '@japieglobal/shared/src/types/click-state';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@japieglobal/shared/src/components';
import { Link, SvgIcon, Theme } from '@mui/material';

export interface OptionIconProps extends CustomIconProps {
  option: string;
  toggleValue?: ClickState | boolean;
  isEnabled?: boolean;
  isMissing?: boolean;
  onHoverText?: string;
}
export const OptionIcon = ({
  option,
  toggleValue,
  isMissing = false,
  isEnabled,
  fontSize,
  color,
  onHoverText,
  onClick,
  ...rest
}: OptionIconProps) => {
  const { t } = useTranslation();
  const icon = optionIcons[option as keyof typeof optionIcons];

  const getStyle = (theme: Theme) => ({
    fontSize: fontSize ?? getFontSize(option),
    color: color ?? getOptionIconColor(isMissing, isEnabled),
    border: `1px solid ${getOptionBorderColor(theme, toggleValue)}`,
    height: '25px',
    cursor: 'pointer',
    borderRadius: '5px',
    padding: '1px',
  });

  interface getOptionIconColor {
    isMissing: boolean;
    active?: boolean;
    isEnabled?: boolean;
    antiLock?: boolean;
  }
  const formatAndShortenOption = (option: string) => {
    const subOption = option.substring(0, 3);
    return subOption[0].toUpperCase() + subOption.substring(1, 3).toLowerCase();
  };

  const getOptionBorderColor = (theme: Theme, toggleValue?: ClickState | boolean) => {
    if (toggleValue === true || toggleValue === ClickState.LOCK) return theme.palette.primary.main;
    if (toggleValue === ClickState.ANTI_LOCK) return theme.palette.warning.main;
    return undefined;
  };

  const getOptionIconColor = (isMissing: boolean, isEnabled?: boolean) => {
    if (isMissing) return 'warning.main';
    if (isEnabled) return 'primary.main';
    return '#d3d3cd';
  };

  const getStrokeWidth = (option: string) => {
    if (option === 'Premium audio') return 2;
    return 0;
  };

  const getFontSize = (option: string) => {
    if (option === 'Adaptive Cruise Control' || option === 'Premium audio' || option === 'M pakket') return 35;
    if (option === 'AMG pakket') return 50;
    if (option === 'S-line') return 40;
    return 22;
  };

  return (
    <Tooltip onHoverContent={onHoverText ?? t(option.toUpperCase())}>
      {icon ? (
        <SvgIcon
          onClick={onClick}
          component={icon}
          inheritViewBox
          sx={getStyle}
          strokeWidth={getStrokeWidth(option)}
          stroke={'currentColor'}
          style={{ display: 'flex', alignItems: 'center' }}
          {...rest}
        />
      ) : (
        <Link
          href="#"
          onClick={onClick as React.MouseEventHandler}
          sx={(theme) => ({ ...getStyle(theme), textDecoration: 'none', fontSize: 'inherit' })}
        >
          {formatAndShortenOption(t(option.toUpperCase()))}
        </Link>
      )}
    </Tooltip>
  );
};
