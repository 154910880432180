import React from 'react';
import { Box } from '@mui/material';
import { ClicksConfig, ClicksConfigKey } from '@japieglobal/shared/src/api';
import { NumberInput, TextInput } from '@japieglobal/shared/src/components';

interface ClicksItem {
  clicksConfig: ClicksConfig;
  clicksKey: keyof ClicksConfig;
  value?: string;
  multiline?: boolean;
  required?: boolean;
  onConfigChange: (value, key) => void;
  isThreshold?: boolean;
}
export const ClicksItem = ({
  clicksConfig,
  clicksKey,
  value,
  multiline = false,
  onConfigChange,
  required = true,
  isThreshold,
}: ClicksItem) => {
  if (clicksConfig) {
    return (
      <Box mb={2}>
        {isThreshold ? (
          <NumberInput
            required={required}
            label={clicksKey.replace('_', ' ')}
            value={(value ?? clicksConfig[clicksKey]) as number}
            setValue={(value) => onConfigChange(value, clicksKey)}
            endAdornment="%"
            disableDecimals
          />
        ) : (
          <TextInput
            required={required}
            value={value ?? clicksConfig[clicksKey]?.toString()}
            label={clicksKey.replace('_', ' ')}
            multiline={multiline}
            setValue={(value) => {
              let clickValue = clicksKey === ClicksConfigKey.CREDENTIALS ? JSON.parse(value) : value;
              clickValue = !clickValue && ClicksConfigKey.CLICKS_THRESHOLD ? null : clickValue;
              onConfigChange(clickValue, clicksKey);
            }}
          />
        )}
      </Box>
    );
  }
};
