import { CancelTokenSource } from 'axios';
import { responseFileDownload } from '../utils/downloadFile';
import { instance } from './instance';
import { User } from './user';

const activityFileDownload = () => responseFileDownload(`activity-log-${new Date().toLocaleString()}`);

interface UserStats {
  color?: string;
  id: string;
  email: string;
  name: string;
  dealer: string;
  totalActivities: number;
}

export interface UserActivityList {
  [key: string]: UserActivity;
}

export interface UserActivity {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  LAST_ACTIVITY: string;
  [key: string]: number;
}

export interface SuperAdminResponseType extends User {
  ActivationTokenExpiresAt: any;
  ActivationToken: any;
  lastActivity: any;
}

export interface UserFilterItem {
  filterOn: string;
  filter: string;
}

export type UserStatsResponse = Record<string, UserStats[]>;

export interface UserStaticsListResponse {
  data: SuperAdminResponseType[];
  total: number;
}

interface GetUserStatisticListBody {
  filters?: UserFilterItem[];
  limit: number;
  offset: number;
  date_from?: string;
  date_to?: string;
  is_service_account: boolean;
  show_super_admins: boolean;
  sort?: any[];
  activities?: string[];
  permissions?: string[];
  cancelToken: CancelTokenSource;
}
export const getUserStatisticList = async (body: GetUserStatisticListBody): Promise<UserStaticsListResponse> =>
  instance.post(`/activity/users/`, body, { cancelToken: body.cancelToken.token }).then((res) => res.data); // TODO: Why is this a post

interface GetUserActivityParams {
  date_from?: string;
  date_to?: string;
}
export const getUserActivity = async (userId: string, props: GetUserActivityParams): Promise<UserActivityList> =>
  instance.get(`/activity/users/${userId}`, { params: props }).then((res) => res.data);

interface GetActivitiesParams {
  limit: number;
  offset: number;
  date_from?: string;
  date_to?: string;
  is_service_account: boolean;
  show_super_admins: boolean;
  sort?: any[];
  activity?: string;
  user_id?: string;
}
export const getActivities = async (params: GetActivitiesParams): Promise<UserActivityList> =>
  instance.get(`/activity/`, { params }).then((res) => res.data);

interface ExportActivitiesParams {
  date_from?: string;
  date_to?: string;
  is_service_account: boolean;
  show_super_admins: boolean;
  sort?: any[];
  activity?: string;
  user_id?: string;
}
export const exportActivities = async (params: ExportActivitiesParams) =>
  instance.get(`/activity/export_list`, { params, responseType: 'blob' }).then(activityFileDownload());

interface UserStatsBody {
  date_from?: string;
  date_to?: string;
  is_service_account?: boolean;
  show_super_admins: boolean;
  ids?: string[];
  filters?: UserFilterItem[];
  activities?: string[];
  permissions?: string[];
}
export const userStats = async (body: UserStatsBody): Promise<UserStatsResponse> =>
  instance.post(`/activity/charts/users/`, body).then((res) => res.data);

interface GetGlobalActivityParams {
  date_from?: string;
  date_to?: string;
}
export const getGlobalActivity = async (params: GetGlobalActivityParams): Promise<UserActivityList> =>
  instance.get(`/activity/global`, { params }).then((res) => res.data);

interface UserStatsAllDownloadBody {
  date_from?: string;
  date_to?: string;
  is_service_account: boolean;
  show_super_admins: boolean;
  filters?: UserFilterItem[];
  activities?: string[];
  permissions?: string[];
}
export const userStatsAllDownload = async (body: UserStatsAllDownloadBody) =>
  instance.post(`/activity/export`, body, { responseType: 'blob' }).then(activityFileDownload());

interface DealerStatisticListBody {
  filters?: UserFilterItem[];
  limit: number;
  offset: number;
  date_from?: string;
  date_to?: string;
  sort?: any[];
  activities?: string[];
  permissions?: string[];
}
export const dealerStatisticList = async (body: DealerStatisticListBody): Promise<UserStaticsListResponse> =>
  instance.post(`/activity/dealers/`, body).then((res) => res.data);

interface DealerStatsBody {
  date_from?: string;
  date_to?: string;
  filters?: UserFilterItem[];
}
export const dealerStats = async (body: DealerStatsBody): Promise<UserStatsResponse> =>
  instance.post(`/activity/charts/dealers/`, body).then((res) => res.data);

interface DealerStatsAllDownloadBody {
  date_from?: string;
  date_to?: string;
  filters?: UserFilterItem[];
}
export const dealerStatsAllDownload = async (body: DealerStatsAllDownloadBody) =>
  instance.post(`/activity/export_dealers`, body, { responseType: 'blob' }).then(activityFileDownload());

export enum ActivityType {
  VIEW_TOP_DEALERS = 'VIEW_TOP_DEALERS',
}
interface CreateActivityBody {
  activity: ActivityType;
}
export const createActivity = async (body: CreateActivityBody): Promise<any> =>
  instance.post(`/activity/`, body).then((res) => res.data);
