import React from 'react';
import IconButton from '@mui/material/IconButton';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

export const VideoButton = ({ setVideoClicked }: { setVideoClicked: (item: boolean) => void }) => {
  const handleClickOpen = () => {
    setVideoClicked(true);
  };
  return (
    <IconButton onClick={handleClickOpen} sx={{ padding: 0 }}>
      <OndemandVideoIcon color="primary" fontSize="small" />
    </IconButton>
  );
};
