import React, { useContext, useState } from 'react';
import { Grid2 } from '@mui/material';
import { useDebouncedEffect, useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { TranslationFileType } from '@japieglobal/shared/src/i18n/i18n';
import {
  ColoredBorderButton,
  emptySelectOption,
  SelectInput,
  SelectOption,
  TextInput,
} from '@japieglobal/shared/src/components';
import { downloadTranslationFile, uploadTranslationFile, UserPermission } from '@japieglobal/shared/src/api/services';
import { UploadTranslations } from './upload-translations';
import { Language } from '@japieglobal/shared/src/types';
import { SuperAdminPageContainer } from '../../super-admin-page-container';
import { hasPermission } from '@japieglobal/shared/src/utils';
import { UserContext } from '@japieglobal/shared/src/user-context';

type Translations = { [key: string]: { [key in Language]: string } };

interface TranslationsRowProps {
  translationsKey: string;
  value: { [key in Language]: string };
  setTranslations: React.Dispatch<React.SetStateAction<Translations>>;
}

const TranslationsRow = React.memo(({ translationsKey, setTranslations, value }: TranslationsRowProps) => (
  <Grid2 pt={3} container gap={2}>
    {Object.values(Language).map((languageKey) => (
      <TextInput
        key={languageKey}
        value={value?.[languageKey.toUpperCase()]}
        setValue={(newValue) =>
          setTranslations((prevTranslations) => ({
            ...prevTranslations,
            [translationsKey]: {
              ...prevTranslations[translationsKey],
              [languageKey.toUpperCase()]: newValue,
            },
          }))
        }
        label={languageKey}
      />
    ))}
  </Grid2>
));

export const EditTranslations = () => {
    const { user } = useContext(UserContext);
    const { snackbarErrorHandler, snackbarSuccessMessage } = useSnackbarErrorHandler();

  const [fileType, setFileType] = useState<TranslationFileType>(TranslationFileType.FRONTEND);

  const [translations, setTranslations] = useState<Translations>({});
  const [selectedTranslationKey, setSelectedTranslationKey] = useState<SelectOption>(emptySelectOption);

  useDebouncedEffect(
    () => {
      downloadTranslationFile(fileType, true)
        .then((res) => {
          setTranslations(res.data);
          setSelectedTranslationKey({ value: Object.keys(res.data)[0], label: Object.keys(res.data)[0] });
        })
        .catch(snackbarErrorHandler);
    },
    300,
    [fileType, snackbarErrorHandler],
  );

  const onSave = () => {
    const formData = new FormData();
    const formattedJson = JSON.stringify(translations, null, 2);

    const jsonBlob = new Blob([formattedJson], { type: 'application/json' });
    formData.append('file', jsonBlob, 'translations.json');

    uploadTranslationFile(fileType, formData)
      .then(() => snackbarSuccessMessage('Changes have been saved'))
      .catch(snackbarErrorHandler);
  };

  return (
    <SuperAdminPageContainer>
      {hasPermission(user, UserPermission.SYSTEM_ADMIN) && (
        <UploadTranslations fileType={fileType} setFileType={setFileType} />
      )}
      <SelectInput
        options={Object.entries(translations).map(([key]) => ({ value: key, label: key }))}
        value={selectedTranslationKey}
        setValue={setSelectedTranslationKey}
        // freeSolo NOTE: Turned free solo off for now, but turning it on will allow users to create new keys, but this will create some other issues, for example creating empty keys.
        disableFilterStartWith
        label="Translation key"
      />
      <TranslationsRow
        translationsKey={selectedTranslationKey.value}
        value={translations[selectedTranslationKey.value]}
        setTranslations={setTranslations}
      />
      <ColoredBorderButton onClick={onSave}>Save</ColoredBorderButton>
    </SuperAdminPageContainer>
  );
};
