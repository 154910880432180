import React, { useMemo } from 'react';
import { MultiSelectFilter, useAppSearchParams, AppSearchParamKeys } from '@japieglobal/shared/src/components';
import { CarHeader, CarHeaderProps } from '@japieglobal/shared/src/api';

export const FilterFuel = () => {
  const [{ make }] = useAppSearchParams([AppSearchParamKeys.MAKE]);
  const carHeaderOptions: CarHeaderProps = useMemo(() => ({ type: CarHeader.FUEL, make }), [make]);
  return (
    <MultiSelectFilter
      searchParamKey={AppSearchParamKeys.FUELS}
      carHeaderOptions={carHeaderOptions}
      labelTranslationKey="FUEL"
      disableCloseOnSelect
    />
  );
};
