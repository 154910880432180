import { ClickAwayListener, Popper, PopperPlacementType } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { styled } from '@mui/system';

const StyledPopper = styled(Popper)({
  '&.popover': {
    pointerEvents: 'none',
  },
  '&.paper': {
    borderRadius: '4px',
    padding: '8px',
    backgroundColor: 'white',
    zIndex: 9999,
  },
});
export interface TooltipProps {
  onHoverContent?: JSX.Element | string;
  onClickContent?: JSX.Element | string;
  // Putting this boolean on true it possible to create a tooltip that is scrollable and works with clicks.
  // It keeps the tooltip displayed when hovering over its content.
  interactive?: boolean;
  placement?: PopperPlacementType;
  // With this boolean you can choose to override the default behavior of the tooltip's on close
  // Instead of closing after not hovering the item, it will close on click instead
  closeOnClick?: boolean;
  popUpButtonClicked?: boolean;
}

export const Tooltip = React.memo(
  ({
    children,
    onHoverContent = '',
    onClickContent = '',
    interactive = false,
    placement = 'bottom',
    closeOnClick = false,
    popUpButtonClicked,
  }: PropsWithChildren<TooltipProps>) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    const open = popUpButtonClicked ? false : Boolean(anchorEl);

    const conditionalEventsObject = {
      ...(onHoverContent && { ['onMouseEnter']: handlePopoverOpen }),
      ...(onClickContent && { ['onClick']: handlePopoverOpen }),
    };

    return onClickContent || onHoverContent ? (
      <ClickAwayListener onClickAway={handlePopoverClose}>
        <span onMouseLeave={closeOnClick ? undefined : handlePopoverClose} {...conditionalEventsObject}>
          {children}
          <StyledPopper
            className={`${interactive ? '' : 'popover'} paper`}
            style={{ border: 1, borderColor: 'black', borderStyle: 'solid' }}
            open={open}
            anchorEl={anchorEl}
            popperOptions={{
              placement,
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -5],
                  },
                },
              ],
            }}
          >
            {onClickContent || onHoverContent}
          </StyledPopper>
        </span>
      </ClickAwayListener>
    ) : (
      <>{children}</>
    );
  },
);
