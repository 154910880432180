import React from 'react';
import { AppSearchParamKeys, ToggleFilter } from '@japieglobal/shared/src/components';

export const TaxationFilterPriceExex = () => (
  <ToggleFilter
    disableTripleState
    labelTranslationKey="PRICE_EXEX"
    searchParamKey={AppSearchParamKeys.SHOW_PRICE_EXEX}
  />
);
