import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';
import { generalIconsList } from './general-icons-list';

export interface CustomIconProps extends SvgIconProps {}

export interface GeneralIconProps extends CustomIconProps {
  iconKey: keyof typeof generalIconsList;
}
export const CustomIcon = ({ viewBox, iconKey, ...restProps }: GeneralIconProps) => (
  <SvgIcon
    {...restProps}
    component={generalIconsList[iconKey]}
    inheritViewBox={viewBox ? false : true}
    sx={{ fontSize: restProps.fontSize ? undefined : 20 }}
  />
);
