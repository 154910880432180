import { styled } from '@mui/material';
import React from 'react';
import { CarMessage } from '@japieglobal/shared/src/car-message/car-message-types';
import { useCarMessageStyles } from './car-message-styles';
import { formatDateAsDate, formatDateAsHourAndMinute } from '@japieglobal/shared/src/utils';

const CommentBalloonRowWrapper = styled(`div`)({
  display: 'flex',
  marginBottom: '10px',
});

const CommentWrapper = styled(`div`)({
  margin: '20px 0',
});

export const CarMessageOwn = React.memo(function CarMessageOwn({ msg, carId }: { msg: CarMessage; carId: number }) {
  const classes = useCarMessageStyles();
  return (
    <CommentWrapper key={carId}>
      <CommentBalloonRowWrapper>
        <div className={`${classes['comment--own.comment__balloon']} ${classes.comment__balloon}`}>
          <span className={`${classes['comment--own.comment__triangle']} ${classes.comment__triangle}`} />
          <span className={classes.commentText}>{msg.content}</span>
        </div>
        <div className={`${classes['comment--own.comment__user']} ${classes.comment__user}`}>{msg.sender_name}</div>
      </CommentBalloonRowWrapper>
      <div className={`${classes['comment--own.comment__timestamp']} ${classes.comment__timestamp}`}>
        Geplaatst op {formatDateAsDate(new Date(msg.created))} om {formatDateAsHourAndMinute(new Date(msg.created))}
      </div>
    </CommentWrapper>
  );
});

export const CarMessageOther = React.memo(function CarMessageOther({ msg, carId }: { msg: CarMessage; carId: number }) {
  const classes = useCarMessageStyles();
  return (
    <CommentWrapper key={carId}>
      <CommentBalloonRowWrapper>
        <div className={classes.comment__user}>{msg.sender_name}</div>
        <div className={classes.comment__balloon}>
          <span className={classes.comment__triangle} />
          <span className={classes.commentText}>{msg.content}</span>
        </div>
      </CommentBalloonRowWrapper>
      <div className={classes.comment__timestamp}>
        Geplaatst op {formatDateAsDate(new Date(msg.created))} om {formatDateAsHourAndMinute(new Date(msg.created))}
      </div>
    </CommentWrapper>
  );
});
