import React from 'react';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import PublicIcon from '@mui/icons-material/Public';
import { CarWindowMap } from '../map/car-compare-map';
import { CarResult } from '@japieglobal/shared/src/types';
import { useAppSearchParams } from '@japieglobal/shared/src/components';

interface CarWindowTableCellsProps {
  ownCar: CarResult;
}

export const CarWindowMapHeaderCell = ({ ownCar }: CarWindowTableCellsProps) => {
  const [{ click_location, click_polygon }, setSearchParams] = useAppSearchParams();
  const unlockMapIconActive = click_location !== undefined || click_polygon !== undefined;

  const onClick = () => {
    const value = click_polygon || click_location;
    setSearchParams({ click_location: !value, click_polygon: !value });
  };

  const icon = click_location || click_polygon ? <VpnLockIcon onClick={onClick} /> : <PublicIcon onClick={onClick} />;
  return ownCar?.window_car_ids?.length ? (
    <>
      <CarWindowMap ownCar={ownCar} windowCarIds={ownCar.window_car_ids} />
      {unlockMapIconActive && icon}
    </>
  ) : (
    <></>
  );
};
