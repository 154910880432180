import React, { useMemo } from 'react';
import {
  SelectInput,
  SelectOption,
  valuesToSelectOptions,
  valueToSelectOption,
} from '@japieglobal/shared/src/components';
import {
  CsmReport,
  SalesReportRow,
  SuperAdminResponseType,
  UpdateDealerAccountBody,
} from '@japieglobal/shared/src/api';
import { UpdateDealerAccountFuncType } from '../types';

interface SuperAdminSelectCellProps {
  row: CsmReport | SalesReportRow;
  superAdmins: SuperAdminResponseType[];
  onDealerUpdated: UpdateDealerAccountFuncType;
  editKey: keyof UpdateDealerAccountBody;
}

export const SuperAdminSelectCell = ({ onDealerUpdated, row, superAdmins, editKey }: SuperAdminSelectCellProps) => {
  const options = useMemo(
    () => valuesToSelectOptions(superAdmins.map((superAdmin) => superAdmin.full_name || superAdmin.email)),
    [superAdmins],
  );
  const handleSelect = (selectedOptions: SelectOption) => {
    onDealerUpdated({ row, key: editKey, value: selectedOptions.value });
  };

  return <SelectInput value={valueToSelectOption(row[editKey])} setValue={handleSelect} options={options} />;
};
