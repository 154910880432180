import React from 'react';
import { OptionIcon } from '@japieglobal/shared/src/components';
import { ClickState } from '@japieglobal/shared/src/types/click-state';
import { clickStateParser } from '../../../../utils/tri-state-parser';
import { useAppSearchParams } from '@japieglobal/shared/src/components';
import { CarResult } from '@japieglobal/shared/src/types';

interface OptionsClickLinkProps {
  option: string;
  ownCar: CarResult;
  carHasOption?: boolean;
  isMissing?: boolean;
}

export const CarWindowOption = React.memo(({ option, ownCar, carHasOption, isMissing }: OptionsClickLinkProps) => {
  const [, setSearchParams] = useAppSearchParams();
  const value = JSON.parse(ownCar.click?.options || '{}')[option] as ClickState;

  const updateClickOptions = () => {
    const newValue = { ...JSON.parse(ownCar.click?.options || '{}'), [option]: clickStateParser(value) };
    setSearchParams({ click_options: newValue });
  };

  return (
    option && (
      <OptionIcon
        onClick={updateClickOptions}
        option={option}
        toggleValue={value}
        isMissing={isMissing}
        isEnabled={carHasOption}
      />
    )
  );
});
