import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import DeleteIcon from '@mui/icons-material/Delete';
import GetAppIcon from '@mui/icons-material/GetApp';
import RefreshIcon from '@mui/icons-material/Refresh';
import * as XLSX from 'xlsx';
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Switch,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import {
  applyCustomDataCars,
  deleteFile,
  downloadFile,
  existsFile,
  uploadFile,
  UploadType,
  UserPermission,
} from '@japieglobal/shared/src/api';
import { UserContext } from '@japieglobal/shared/src/user-context';
import { hasPermission, isSuperAdmin } from '@japieglobal/shared/src/utils';
import { settings } from '@japieglobal/shared/src/settings';
import { ConfirmationDialog } from '@japieglobal/shared/src/components';

const useStyles = makeStyles(() => ({
  main: {
    padding: 20,
    margin: 20,
  },
  table: {
    width: 400,
  },
  container: {
    color: '#616161',
  },
  dropMessage: {
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  dropImage: {
    display: 'block',
  },
  formControl: {
    minWidth: 150,
    paddingTop: 5,
  },
}));
const Wrapper = styled(`div`)({ marginTop: '30px' });

export const UploadFileView = React.memo(() => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const classes = useStyles();
  const { snackbarErrorMessage, snackbarSuccessMessage, snackbarWarningMessage } = useSnackbarErrorHandler();
  const filenames = [
    ['VIN', `${user.dealer}_vin.csv`, `example_vin.csv`, UploadType.VIN],
    ['Leads', `${user.dealer}_leads.csv`, `example_leads.csv`, UploadType.LEADS],
    ['Purchase', `${user.dealer}_purchase.csv`, `example_purchase.csv`, UploadType.PRICE_PURCHASE],
    ['Location', 'inkopers.csv', `example_location.csv`, UploadType.LOCATION],
    ['Supply Type: Demo', `${user.dealer}_is_demo.csv`, `example_supply_type.csv`, UploadType.DEMO],
    ['Supply Type: New', `${user.dealer}_is_new.csv`, `example_supply_type.csv`, UploadType.NEW],
    ['Stock Days Client', 'stock_days_client.csv', `example_stock_days_client.csv`, UploadType.STOCK_DATE],
  ].filter((f) => hasPermission(user, ('upload_' + f[3].toLowerCase()) as UserPermission));
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const [uploadType, setUploadType] = useState<UploadType>(filenames[0][3]);
  const [fileExists, setFileExists] = useState<boolean>(false);
  const [append, setAppend] = useState<boolean>(true);

  function exampleFile(type: UploadType, filenames) {
    return filenames.find((data) => data[3] === type)[2];
  }

  const refreshFileExists = useCallback(() => {
    setFileExists(false);
    existsFile({ upload_type: uploadType }).then((res) => {
      setFileExists(res.length > 0);
    });
  }, [uploadType]);

  useEffect(() => {
    refreshFileExists();
  }, [refreshFileExists]);

  const refreshCars = useCallback(
    (uploadType: UploadType) => {
      setLoading(true);
      applyCustomDataCars(user.dealer, uploadType.toLowerCase())
        .then(() => {
          snackbarSuccessMessage(t('REFRESH_SUCCESS'));
        })
        .finally(() => setLoading(false));
    },
    [user, snackbarSuccessMessage, t],
  );

  const removeFile = useCallback(() => {
    deleteFile({ upload_type: uploadType })
      .then(() => {
        snackbarSuccessMessage(t('REMOVE_SUCCESS'));
        refreshFileExists();
      })
      .catch(() => {
        snackbarErrorMessage(t('REMOVE_ERROR'));
      });
  }, [uploadType, snackbarErrorMessage, snackbarSuccessMessage, t, refreshFileExists]);

  const handleDownloadFile = useCallback(() => {
    downloadFile({ upload_type: uploadType })
      .then(async (res) => {
        const result = res.data;
        const headers = Object.keys(result[0]);
        const csvRows = result.map((row) => headers.map((field) => JSON.stringify(row[field] ?? '')).join(','));

        const csvString = [headers.join(','), ...csvRows].join('\n');
        const a = document.createElement('a'); // TODO: See downloadfile function
        const blob = new Blob([csvString], { type: 'text/csv' });

        a.href = URL.createObjectURL(blob);
        a.download = (res.headers['content-disposition']?.split('filename=')[1] || 'file').replaceAll('"', '');
        a.click();
        snackbarSuccessMessage(t('DOWNLOAD_SUCCESS'));
      })
      .catch(() => {
        snackbarErrorMessage(t('DOWNLOAD_ERROR'));
      });
  }, [uploadType, snackbarErrorMessage, snackbarSuccessMessage, t]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setLoading(true);
      const file = acceptedFiles[0];
      const reader = new FileReader();
      const result: any[] = [];

      reader.onload = (e) => {
        let contents: string;
        if (file.name.endsWith('xlsx')) {
          const data = new Uint8Array(e.target!.result as ArrayBuffer);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          contents = XLSX.utils.sheet_to_csv(workbook.Sheets[sheetName]);
        } else {
          contents = e.target!.result as string;
        }
        const lines = contents.split('\n');
        const delimiter = lines[0].includes(';') ? ';' : ',';
        const keys = lines[0].split(delimiter).map((l) => l.trim());
        let identifierType = settings().showLicensePlate ? 'license_plate' : 'reference_code';
        if (keys[0] == 'license_plate') {
          keys[0] = 'identifier';
        }
        if (keys[0] == 'reference_code') {
          keys[0] = 'identifier';
          identifierType = 'reference_code';
        }
        const identifiers: Set<string> = new Set();
        if (keys[0] !== 'identifier') {
          snackbarErrorMessage(t('INVALID_HEADERS'));
          return;
        }
        lines
          .slice(1)
          .filter((s) => s)
          .forEach((line) => {
            const parts = line.split(delimiter);
            const row = {};
            parts.forEach((p, index) => {
              p = p.trim().replaceAll('"', '');
              const key = keys[index];
              if (key === 'price_purchase') {
                p = p.split(',')[0].split('.')[0];
              }
              row[key] = p || null;
            });
            if (!identifiers.has(row['identifier'])) {
              identifiers.add(row['identifier']);
              result.push(row);
            } else {
              snackbarWarningMessage('Duplicate detected: ' + row['identifier']);
            }
          });

        uploadFile(result, { upload_type: uploadType, append, identifier_type: identifierType })
          .then(() => {
            snackbarSuccessMessage(t('UPLOAD_SUCCESS'));
            setFileExists(true);
          })
          .catch(() => {
            snackbarErrorMessage(t('UPLOAD_ERROR'));
          })
          .finally(() => setLoading(false));
      };
      if (file.name.endsWith('.xlsx')) {
        reader.readAsArrayBuffer(file);
      } else {
        reader.readAsText(file);
      }
    },
    [uploadType, append, snackbarWarningMessage, snackbarSuccessMessage, t, snackbarErrorMessage],
  );
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({ onDrop });

  const activeStyle = {
    borderColor: '#2196f3',
  };

  const acceptStyle = {
    borderColor: '#96BF31',
  };

  const rejectStyle = {
    borderColor: '#ff1744',
  };

  const baseStyle = {
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    transition: 'border .24s ease-in-out',
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDragActive, isDragReject, isDragAccept],
  );

  return (
    <Wrapper>
      <Grid2 container spacing={3}>
        <Grid2 className={classes.container} size={9}>
          <div {...getRootProps({ style })} className={classes.main}>
            <input {...getInputProps()} />
            <div className={classes.dropMessage}>
              <img src="/dragdrop.png" width={160} alt="drag-n-drop" />
              <div>{t('UPLOAD_DROP_MESSAGE')}</div>
            </div>
          </div>
        </Grid2>
        <Grid2 className={classes.container} size={3}>
          <FormControl margin="normal" className={classes.formControl} fullWidth>
            <InputLabel>{t('File')}</InputLabel>
            <Select value={uploadType} onChange={(e) => setUploadType(e.target.value as UploadType)} name="filename">
              {filenames.map(([label, path, , type]) => (
                <MenuItem key={type} value={type}>
                  {label} ({path})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {user.locations.length == 0 && (
            <FormControlLabel
              label={t('APPEND')}
              control={<Switch color="primary" checked={append} onChange={() => setAppend(!append)} name="append" />}
            />
          )}
          <Button href={`/upload-input/${exampleFile(uploadType, filenames)}`} startIcon={<GetAppIcon />}>
            {t('FILE_VALUATION_INPUT_EXAMPLE')}
          </Button>
          {fileExists && (
            <>
              <Button onClick={handleDownloadFile} startIcon={<GetAppIcon />}>
                {t('DOWNLOAD')}
              </Button>
              {user.locations.length == 0 && (
                <>
                  {confirmDelete && (
                    <ConfirmationDialog
                      closeDialog={() => setConfirmDelete(false)}
                      yesAction={removeFile}
                      title={t('REMOVE_FILE')}
                      question={t('REMOVE_FILE_QUESTION')}
                    />
                  )}
                  <Button onClick={() => setConfirmDelete(true)} startIcon={<DeleteIcon />}>
                    {t('REMOVE')}
                  </Button>
                </>
              )}
            </>
          )}
          {isSuperAdmin(user) && (
            <Button onClick={() => refreshCars(uploadType)} startIcon={<RefreshIcon />}>
              {t('REFRESH_CARS')}
            </Button>
          )}
          {loading && <CircularProgress />}
        </Grid2>
      </Grid2>
    </Wrapper>
  );
});
