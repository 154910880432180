import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export function useSnackbarErrorHandler() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const snackbarErrorMessage = useCallback(
    (msg: string) => {
      enqueueSnackbar(msg, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    },
    [enqueueSnackbar],
  );

  const snackbarSuccessMessage = useCallback(
    (msg: string) => {
      enqueueSnackbar(msg, {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    },
    [enqueueSnackbar],
  );

  const snackbarWarningMessage = useCallback(
    (msg: string) => {
      enqueueSnackbar(msg, {
        variant: 'warning',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    },
    [enqueueSnackbar],
  );

  const snackbarErrorHandler = useCallback(
    (err: Error) => {
      enqueueSnackbar(t(err.message), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    },
    [enqueueSnackbar, t],
  );
  return { snackbarErrorHandler, snackbarErrorMessage, snackbarSuccessMessage, snackbarWarningMessage };
}
