import { styled } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { Tooltip } from '../tooltip/tooltip';

const TableStyled = styled(`table`)({ fontSize: 12 });
const TdStyled1 = styled(`td`)({ fontWeight: 600, padding: 0, width: '160px' });
const TdStyled2 = styled(`td`)({ padding: 0 });

interface InformationPopoverProps {
  information: Array<Array<any>>;
  parentKey: string;
  interactiveTooltip?: boolean;
}

export const InformationPopover = React.memo(
  ({ information, parentKey, children, interactiveTooltip }: PropsWithChildren<InformationPopoverProps>) => (
    <Tooltip
      interactive={interactiveTooltip}
      onHoverContent={
        <TableStyled>
          <tbody>
            {information?.map(([val1, val2], index) => (
              <tr key={`${parentKey}-${index}-${val1}`}>
                <TdStyled1>{val1}</TdStyled1>
                <TdStyled2>{val2}</TdStyled2>
              </tr>
            ))}
          </tbody>
        </TableStyled>
      }
    >
      {children}
    </Tooltip>
  ),
);
