export const getVideoUrl = (item: string, language: string) =>
  Videos[item][language] ? Videos[item][language] : Videos[item].en;

export const Videos = {
  etr: {
    nl: 'https://share.synthesia.io/embeds/videos/f29c7224-4f83-4408-bcb7-4c63767aa928',
    de: 'https://share.synthesia.io/embeds/videos/e0b65406-f0bd-44b0-a60d-8bc26e08dced',
    en: 'https://share.synthesia.io/embeds/videos/354f3943-4ff3-43c6-bcb6-5d6461948a48',
  },
  apr: {
    nl: 'https://share.synthesia.io/embeds/videos/82f8bcdd-5c68-4c08-81e7-7c5d2162cd79',
    de: 'https://share.synthesia.io/embeds/videos/d37941f6-81c9-485d-8f03-23f791eff519',
    en: 'https://share.synthesia.io/embeds/videos/87d1ca70-b60a-489e-a887-315a1532774f',
  },
};
