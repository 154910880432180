import { AppSearchParamKeys } from '@japieglobal/shared/src/components';
import { CsmReportKey } from '@japieglobal/shared/src/api';
import { CsmColumn } from '../../shared/csm-and-sales-report/types';

export const csmColumns: CsmColumn[] = [
  {
    field: CsmReportKey.STATUS,
    headerName: 'Status',
    headerDescription: 'Status',
    type: 'string',
    cellRender: 'statusSelect',
    searchParamKey: AppSearchParamKeys.STATUSES,
    selectType: 'multi',
  },
  {
    field: CsmReportKey.DEALER,
    headerName: 'Dealer',
    headerDescription: 'Dealer',
    flex: 3,
    type: 'string',
    cellRender: 'link',
    searchParamKey: AppSearchParamKeys.DEALER,
    selectType: 'single',
  },
  {
    field: CsmReportKey.TOTAL_USAGE_CHANGE,
    headerName: 'Total usage change (%)',
    headerDescription: 'Total usage change (%)',
    type: 'string',
    cellRender: 'basic',
    hoverRender: 'table',
    searchParamKey: AppSearchParamKeys.TOTAL_USAGE_CHANGE,
    selectType: 'single',
  },
  {
    field: CsmReportKey.TOTAL_CALLS_CHANGE,
    headerName: 'Total calls change (%)',
    headerDescription: 'Total calls change (%)',
    type: 'string',
    cellRender: 'basic',
    hoverRender: 'table',
    searchParamKey: AppSearchParamKeys.TOTAL_CALLS_CHANGE,
    selectType: 'single',
  },
  {
    field: CsmReportKey.TOTAL_USAGE_0_30,
    headerName: 'Total usage 0 - 30 days (%)',
    headerDescription: 'Total usage 0 - 30 days (%)',
    type: 'string',
    cellRender: 'basic',
    searchParamKey: AppSearchParamKeys.TOTAL_USAGE_0_30,
    flex: 1.2,
    selectType: 'single',
  },
  {
    field: CsmReportKey.ARR_CATEGORY,
    headerName: 'Arr Category',
    headerDescription: 'Arr Category',
    type: 'number',
    cellRender: 'basic',
    searchParamKey: AppSearchParamKeys.ARR_CATEGORY,
    flex: 1.2,
    selectType: 'multi',
  },
  {
    field: CsmReportKey.HEALTH,
    headerName: 'Health',
    headerDescription: 'Health',
    type: 'string',
    cellRender: 'healthSelect',
    searchParamKey: AppSearchParamKeys.HEALTHS,
    selectType: 'multi',
  },
  {
    field: CsmReportKey.END_DATE_CONTRACT,
    headerName: 'END_DATE_CONTRACT',
    headerDescription: 'END_DATE_CONTRACT',
    flex: 2,
    type: 'string',
    cellRender: 'dateStatus',
    searchParamKey: AppSearchParamKeys.END_DATE_CONTRACT,
    selectType: 'single',
  },
  {
    field: CsmReportKey.CSM,
    headerName: 'CSM',
    headerDescription: 'CSM',
    flex: 2,
    type: 'string',
    cellRender: 'superAdminSelect',
    searchParamKey: AppSearchParamKeys.CSM,
    selectType: 'multi',
  },
  {
    field: CsmReportKey.DELIVERY,
    headerName: 'DELIVERY',
    headerDescription: 'DELIVERY',
    flex: 2,
    type: 'string',
    cellRender: 'superAdminSelect',
    searchParamKey: AppSearchParamKeys.DELIVERY,
    selectType: 'multi',
  },
  {
    field: CsmReportKey.LAST_COMMENT_DATE,
    headerName: 'Last comment',
    headerDescription: 'Last comment',
    flex: 2,
    type: 'string',
    cellRender: 'date',
    searchParamKey: AppSearchParamKeys.LAST_COMMENT_DATE,
    selectType: 'single',
  },
  {
    field: CsmReportKey.COMMENTS,
    headerName: 'MESSAGES',
    headerDescription: 'MESSAGES',
    type: 'string',
    cellRender: 'comments',
    searchParamKey: AppSearchParamKeys.COMMENTS,
    flex: 1.2,
    selectType: 'single',
  },
];
