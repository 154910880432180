import GetAppIcon from '@mui/icons-material/GetApp';
import { Button, Grid2 } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { TranslationFileType } from '@japieglobal/shared/src/i18n/i18n';
import { FileInput, SelectInput, SelectOption } from '@japieglobal/shared/src/components';
import { downloadTranslationFile, uploadTranslationFile } from '@japieglobal/shared/src/api';

const translationFileTypeOptions: SelectOption[] = Object.values(TranslationFileType).map((fileType) => ({
  value: fileType,
  label: fileType,
}));

interface UploadTranslationsProps {
  fileType: TranslationFileType;
  setFileType: (newFileType: TranslationFileType) => void;
}
export const UploadTranslations = ({ fileType, setFileType }: UploadTranslationsProps) => {
  const { t } = useTranslation();
  const { snackbarErrorMessage, snackbarSuccessMessage } = useSnackbarErrorHandler();

  const downloadFile = useCallback(() => {
    downloadTranslationFile(fileType)
      .then(async (res) => {
        const blob = await res.data;
        const a = document.createElement('a'); // TODO: See downloadfile function
        a.href = URL.createObjectURL(blob);
        a.download = (res.headers['content-disposition']?.split('filename=')[1] || 'file').replaceAll('"', '');
        a.click();
        snackbarSuccessMessage(t('DOWNLOAD_SUCCESS'));
      })
      .catch(() => {
        snackbarErrorMessage(t('DOWNLOAD_ERROR'));
      });
  }, [fileType, snackbarErrorMessage, snackbarSuccessMessage, t]);

  const onDrop = (formData: FormData) => uploadTranslationFile(fileType, formData);

  return (
    <Grid2 container spacing={3}>
      <Grid2 size={9}>
        <FileInput onDropFile={onDrop} />
      </Grid2>

      <Grid2 size={3}>
        <SelectInput
          options={translationFileTypeOptions}
          setValue={(option) => setFileType(option.value as TranslationFileType)}
          value={{ label: fileType, value: fileType }}
        />
        <Button onClick={() => downloadFile()} startIcon={<GetAppIcon />}>
          {t('DOWNLOAD')}
        </Button>
      </Grid2>
    </Grid2>
  );
};
