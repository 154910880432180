import React from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { AppSearchParamKeys, useAppSearchParams } from '@japieglobal/shared/src/components';

export const BannedCarHeaderCell = () => {
  const [{ include_banned }, setSearchParams] = useAppSearchParams([AppSearchParamKeys.INCLUDE_BANNED]);
  return (
    <>
      {include_banned ? (
        <VisibilityIcon onClick={() => setSearchParams({ include_banned: false })} />
      ) : (
        <VisibilityOffIcon onClick={() => setSearchParams({ include_banned: true })} />
      )}
    </>
  );
};
