import { UserPermission } from '@japieglobal/shared/src/api';
import { AppSearchParamKeys, useAppSearchParams } from '@japieglobal/shared/src/components';
import { CarResult } from '@japieglobal/shared/src/types';
import { UserContext } from '@japieglobal/shared/src/user-context';
import { hasPermission } from '@japieglobal/shared/src/utils';
import { Box } from '@mui/material';
import React, { FormEvent, useContext } from 'react';
import { BannedCarsButton } from '../filter/click-filters/banned-cars-button/banned-cars-button';
import { FilterLocation } from '../filter/filter-location';
import { TaxationFilterCurrencyConverter } from './left-side-filters/taxation-filter-currency-converter';
import { TaxationFilterExtraCosts } from './left-side-filters/taxation-filter-extra-costs';
import { TaxationFilterExtraMargin } from './left-side-filters/taxation-filter-extra-margin';
import { TaxationFilterFutureMileage } from './left-side-filters/taxation-filter-future-mileage';
import { TaxationFilterMaturityMonths } from './left-side-filters/taxation-filter-maturity-months';
import { TaxationFilterMaxIterations } from './left-side-filters/taxation-filter-max-iterations';
import { TaxationFilterOptionsDialog } from './left-side-filters/taxation-filter-options-dialog';
import { TaxationOptionToggles } from './left-side-filters/taxation-filter-options-toggles';
import { TaxationFilterPriceExex } from './left-side-filters/taxation-filter-price-exex';
import { TaxationFilterPricingTarget } from './left-side-filters/taxation-filter-pricing-target';
import { TaxationFilterRegion } from './left-side-filters/taxation-filter-region';
import { TaxationFilterScope } from './left-side-filters/taxation-filter-scope';
import { TaxationFilterSearch } from './left-side-filters/taxation-filter-search';
import { TaxationFilterStartPrice } from './left-side-filters/taxation-filter-start-price';
import { TaxationFilterStockDays } from './left-side-filters/taxation-filter-stock-days';

interface TaxationFilterProps {
  handleTaxation: (event: FormEvent) => void;
  car: CarResult | undefined;
}

export const TaxationFilter = React.memo(({ handleTaxation, car }: TaxationFilterProps) => {
  const [{ mileage }] = useAppSearchParams([AppSearchParamKeys.MILEAGE]);
  const { user } = useContext(UserContext);

  return (
    <Box display={'flex'} flexDirection={'column'} rowGap={'10px'}>
      {hasPermission(user, UserPermission.SHOW_MATURITY_VALUE) && (
        <>
          <TaxationFilterMaturityMonths />
          {/* this minimum mileage causes the URL to update with it. */}
          <TaxationFilterFutureMileage mileage={mileage ? Number(mileage) : undefined} />
        </>
      )}
      {hasPermission(user, UserPermission.TAXATION_SCOPE) && <TaxationFilterScope />}
      {user.notifications.includes('super_admin_master_mode') && (
        <>
          <TaxationFilterSearch />
          <TaxationFilterStartPrice />
          <TaxationFilterStockDays />
          <TaxationFilterMaxIterations />
          <TaxationFilterRegion />
        </>
      )}
      {hasPermission(user, UserPermission.TAXATION_LOCATION) && <FilterLocation />}

      <TaxationFilterPricingTarget />
      <TaxationFilterExtraCosts />
      <TaxationFilterExtraMargin />
      <TaxationFilterCurrencyConverter />
      <TaxationFilterPriceExex />
      <TaxationOptionToggles />
      <TaxationFilterOptionsDialog car={car} handleTaxation={handleTaxation} />
      <BannedCarsButton />
    </Box>
  );
});
