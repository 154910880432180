import React from 'react';
import { Grid2 } from '@mui/material';
import { PropsWithChildren } from 'react';
import { SuperAdminPageContainer } from '../../super-admin-page-container';
import { ColoredButton, LoadingWrapper } from '@japieglobal/shared/src/components';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';

export const StyledDiv = styled(`div`)(({ theme }) => ({
  ':nth-of-type(odd)': {
    backgroundColor: 'white',
  },
  ':nth-of-type(even)': {
    backgroundColor: theme.palette.primary.light,
  },
}));

interface RdwPageContainerProps {
  onSubmit: (event: React.FormEvent) => void;
  isLoading?: boolean;
  headerTranslationKey: string;
  buttonTranslationKey?: string;
  ignoreForm?: boolean;
}
export const RdwPageContainer = ({
  children,
  isLoading = false,
  onSubmit,
  headerTranslationKey,
  buttonTranslationKey,
  ignoreForm = false,
}: PropsWithChildren<RdwPageContainerProps>) => {
  const { t } = useTranslation();

  return (
    <SuperAdminPageContainer>
      {ignoreForm ? (
        <Grid2 container display={'flex'} flexDirection={'column'} rowGap={1}>
          <b>{t(headerTranslationKey)}</b>
          <LoadingWrapper isLoading={isLoading}>{children}</LoadingWrapper>
        </Grid2>
      ) : (
        <form onSubmit={onSubmit} style={{ flexFlow: 'row' }}>
          <Grid2 container display={'flex'} flexDirection={'column'} rowGap={1}>
            <b>{t(headerTranslationKey)}</b>
            <LoadingWrapper isLoading={isLoading}>{children}</LoadingWrapper>
            <ColoredButton disabled={isLoading} type="submit">
              {t(buttonTranslationKey || 'SUBMIT')}
            </ColoredButton>
          </Grid2>
        </form>
      )}
    </SuperAdminPageContainer>
  );
};
