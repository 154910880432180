import { Tooltip } from '@japieglobal/shared/src/components';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { Badge } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { SwitchToDealerButton } from '../../switch-to-dealer-button';
import { TaxationButton } from '../../taxation-button';
import { CarScrapeAdminButton } from '../../../monitoring/car-scrape-admin-button';
export interface CarWindowImageCellProps {
  car: CarResult;
  ownCar: CarResult;
  taxationView: boolean;
}
export const CarWindowImageCell = React.memo(({ car, ownCar, taxationView }: CarWindowImageCellProps) => {
  const [largeImagePosition, setLargeImagePosition] = useState<number | undefined>(undefined);

  const imageRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    if (imageRef.current) {
      const imageHeight = imageRef.current.height;
      if (imageHeight > 68) {
        setLargeImagePosition(Math.round(((imageHeight - 68) / imageHeight) * 100));
      }
    }
  }, []);

  return (
    <Tooltip
      onHoverContent={
        <>
          <img width="430px" alt="car large" src={car.image} />
          {!taxationView && <img alt="car large" src={ownCar.image} />}
        </>
      }
    >
      <Badge
        badgeContent={car.rank?.counter}
        color={car.id === ownCar.id ? 'warning' : 'primary'}
        overlap="circular"
        sx={{ '& .MuiBadge-badge': { mr: '4px', top: largeImagePosition } }}
        max={9999}
      >
        <img ref={imageRef} style={{ maxWidth: '100%' }} alt="car" src={car.image} />
        <SwitchToDealerButton car={car} />
        <TaxationButton car={car} />
        <CarScrapeAdminButton
          carDealer={car.dealer?.name}
          country={car.country}
          carUrl={car.url}
          marginBottom={largeImagePosition || 0}
        />
      </Badge>
    </Tooltip>
  );
});
