import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { LoadingWrapper } from '../loading-wrapper/loading-wrapper';
import { useDebouncedEffect } from '../../hooks/use-debounced-effect';
import { dealerSearch, User } from '../../api';
import { isSuperAdmin } from '../../utils';

interface DealersSelectProps {
  selectedDealer?: string;
  onSelect: (newDealer: string) => void;
  user: User;
  clearable?: boolean;
}
export const DealersSelect = ({ onSelect, user, selectedDealer, clearable = false }: DealersSelectProps) => {
  const [dealers, setDealers] = useState<string[]>(!isSuperAdmin(user) ? user.allowed_dealers : []);
  const [value, setValue] = React.useState<string | undefined>(selectedDealer ?? '');
  const [inputValue, setInputValue] = useState<string>(selectedDealer ?? '');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setValue(selectedDealer);
  }, [selectedDealer]);

  useDebouncedEffect(
    () => {
      if (isSuperAdmin(user)) {
        if (inputValue.length > 0) {
          setIsLoading(true);
          dealerSearch({ name: inputValue })
            .then((res) => setDealers(res.results.map((value1) => value1.name)))
            .finally(() => setIsLoading(false));
        } else {
          setDealers([]);
        }
      }
    },
    500,
    [inputValue],
  );

  return (
    <LoadingWrapper isLoading={isLoading}>
      <Autocomplete
        options={dealers}
        value={value}
        disableClearable
        autoHighlight
        onChange={(event, newValue) => {
          setValue(newValue);
          onSelect(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            value={inputValue}
            onBlur={(event) => {
              // NOTE: using onBlur to make it clearable without having to use the disableClearable of MUI because it created a visually bad expierence for the user.
              if (!event.target.value && clearable) {
                setValue('');
                onSelect('');
              }
            }}
            onChange={(event) => setInputValue(event.target.value)}
            InputProps={{
              ...params.InputProps,
            }}
            variant="outlined"
          />
        )}
      />
    </LoadingWrapper>
  );
};
