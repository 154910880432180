import React from 'react';
import { SelectInput, SelectOption } from '@japieglobal/shared/src/components';
import { CsmReport, CsmReportKey, DealerAccountStatus, SalesReportRow } from '@japieglobal/shared/src/api';
import { UpdateDealerAccountFuncType } from '../types';

interface StatusSelectCellProps {
  row: CsmReport | SalesReportRow;
  onDealerUpdated: UpdateDealerAccountFuncType;
}
export const statusOptions = [
  { value: 'FOCUS', label: 'F' },
  { value: 'NORMAL', label: 'N' },
  { value: 'PILOT', label: 'P' },
];

export const StatusSelectCell = ({ onDealerUpdated, row }: StatusSelectCellProps) => {
  const handleSelect = (selectedOptions: SelectOption) => {
    onDealerUpdated({ row, key: CsmReportKey.STATUS, value: selectedOptions.value as DealerAccountStatus });
  };

  const getValueOptions = (item?: DealerAccountStatus) => statusOptions.find((searchItem) => searchItem.value === item);

  return (
    <SelectInput value={getValueOptions(row[CsmReportKey.STATUS])} setValue={handleSelect} options={statusOptions} />
  );
};
