import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import { Tooltip } from '@japieglobal/shared/src/components';
import moment from 'moment';

const getEndContractStatus = (date: string) => {
  const endDateContact = new Date(date);
  const currentDate = new Date();
  const fourMonthsLater = new Date(currentDate);
  fourMonthsLater.setMonth(fourMonthsLater.getMonth() + 4);
  const sixMonthsLater = new Date(currentDate);
  sixMonthsLater.setMonth(sixMonthsLater.getMonth() + 6);

  if (endDateContact <= fourMonthsLater) return 'red';
  else if (endDateContact <= sixMonthsLater) return 'orange';
  else return 'green';
};

export const EndContractCell = ({ endDate }: { endDate: string }) => {
  return (
    <Tooltip onHoverContent={moment(endDate).format('DD-MM-YYYY')}>
      <CircleIcon fontSize="small" sx={{ color: getEndContractStatus(endDate) }} />
    </Tooltip>
  );
};
