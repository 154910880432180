import { TranslationFileType } from '../../i18n/i18n';
import { instance } from './instance';

export const valuateFile = async (
  body: FormData,
  props: {
    template: string;
    use_standard_options: boolean;
    use_paid_options: boolean;
    location_name: string | null;
    enable_other_countries: boolean;
    send_email: boolean;
    currency?: string;
  },
) =>
  instance.post(`/api/valuate/file`, body, {
    responseType: 'blob',
    params: props,
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export enum UploadType {
  VIN = 'VIN',
  LEADS = 'LEADS',
  PRICE_PURCHASE = 'PRICE_PURCHASE',
  LOCATION = 'LOCATION',
  DEMO = 'DEMO',
  NEW = 'NEW',
  STOCK_DATE = 'STOCK_DATE',
}

export const uploadFile = async (
  body: any,
  props: {
    upload_type: UploadType;
    append: boolean;
    identifier_type: string;
  },
) =>
  instance.post(`/extract/upload/` + props.upload_type.toLowerCase(), body, { params: props }).then((res) => res.data);

export const existsFile = async (props: { upload_type: UploadType }) =>
  instance.get(`/extract/upload/` + props.upload_type.toLowerCase(), { params: props }).then((res) => res.data);

export const deleteFile = async (props: { upload_type: UploadType }) =>
  instance.delete(`/extract/upload/` + props.upload_type.toLowerCase(), { params: props }).then((res) => res.data);

export const downloadFile = async (props: { upload_type: UploadType }) =>
  instance.get(`/extract/upload/` + props.upload_type.toLowerCase(), {
    params: props,
  });

export const downloadTranslationFile = async (file_type: TranslationFileType, isJsonResponse = false) =>
  instance.get(`/translations/file`, {
    params: { file_type },
    responseType: isJsonResponse ? 'json' : 'blob',
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const uploadTranslationFile = async (file_type: TranslationFileType, body: FormData) =>
  instance
    .post(`/translations`, body, { params: { file_type }, headers: { 'Content-Type': 'multipart/form-data' } })
    .then((res) => res);
