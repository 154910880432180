import { Tooltip } from '@japieglobal/shared/src/components';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Grid2 } from '@mui/material';
import { styled } from '@mui/system';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { compareMemoProps } from '../../../utils/compare-react-props';
import { CarTurnoverChartTiny } from './car-turnover-chart-tiny';
import { CarTurnover } from '@japieglobal/shared/src/types';
import { UserContext } from '@japieglobal/shared/src/user-context';
import { TurnoverButton, TurnoverButtonProps } from './turnover-button';
import { CarTurnoverTableTiny } from './car-turnover-table-tiny';
import { UserPermission } from '@japieglobal/shared/src/api';
import { hasPermission } from '@japieglobal/shared/src/utils';
import { VideoButton } from '../../../video-explanations/video-button';
import { VideoDialog } from '../../../video-explanations/video-dialog';
import { getVideoUrl } from '../../../video-explanations/videos';
import { useTranslation } from 'react-i18next';

const TurnOverWrapper = styled(`div`)({ marginbottom: '3px', display: 'flex', flexdirection: 'row' });

interface ResultTurnoverButtonProps extends TurnoverButtonProps {
  hideLabel?: boolean;
  takeSpaceWhenEmpty?: boolean;
}

export const ResultTurnoverButton = React.memo(
  ({ car, column, hideLabel, searchParamKey, takeSpaceWhenEmpty = true }: ResultTurnoverButtonProps) => {
    const { user } = useContext(UserContext);
    const { t, i18n } = useTranslation();
    const [videoClicked, setVideoClicked] = useState<boolean>(false);

    const handleCloseVideo = () => {
      setVideoClicked(false);
    };

    const title = column.toUpperCase();
    const [carTurnover, setCarTurnover] = useState<CarTurnover | undefined>(
      car.turnover
        ? { ...car.turnover, etr_trend: car.stat?.turnover_ext_trend, itr_trend: car.stat?.turnover_int_trend }
        : undefined,
    );

    useEffect(() => {
      setCarTurnover(undefined);
    }, [car]);

    const trendIcon = useMemo(() => {
      const trendValue = carTurnover ? carTurnover[`${column}_trend`] : undefined;
      if (trendValue == null) return undefined;
      if (trendValue && trendValue < 0) {
        return <ArrowDropDownIcon />;
      }
      if (trendValue && trendValue > 0) {
        return <ArrowDropUpIcon />;
      }
      return <RemoveIcon />;
    }, [carTurnover, column]);

    return column === 'itr' &&
      (!hasPermission(user, UserPermission.ITR) ||
        (car.stat?.own_supply_window_count && car.stat?.own_supply_window_count < 2)) ? (
      takeSpaceWhenEmpty ? (
        <>&nbsp;</>
      ) : (
        <></>
      ) // By returning a space it will still take up the space which otherwise will be filled by other data.
    ) : (
      <TurnOverWrapper className={`${column}Button`}>
        {!hideLabel && <span style={{ display: 'inline-block', minWidth: '35px' }}>{column.toUpperCase()}:</span>}

        <Tooltip
          interactive={column === 'etr' || column === 'apr'}
          popUpButtonClicked={videoClicked}
          onHoverContent={
            <Grid2 container columnSpacing={1}>
              <Grid2 size={1}>
                <span>{title}</span>
              </Grid2>
              <Grid2 size={11} display={'flex'} flexDirection={'row'}>
                {trendIcon && <Box>{trendIcon}</Box>}
                {(column === 'etr' || column === 'apr') && <VideoButton setVideoClicked={setVideoClicked} />}
              </Grid2>

              <Grid2 size={12}>
                {column === 'apr' ? (
                  <CarTurnoverTableTiny car={car} />
                ) : (
                  <CarTurnoverChartTiny
                    car={car}
                    column={column}
                    carTurnover={carTurnover}
                    setCarTurnover={setCarTurnover}
                  />
                )}
              </Grid2>
            </Grid2>
          }
        >
          <TurnoverButton car={car} column={column} searchParamKey={searchParamKey} />
        </Tooltip>
        {(column === 'etr' || column === 'apr') && (
          <VideoDialog
            videoUrl={getVideoUrl(column, i18n.language)}
            videoClicked={videoClicked}
            handleClose={handleCloseVideo}
            title={t(column.toUpperCase())}
          />
        )}
      </TurnOverWrapper>
    );
  },
  compareMemoProps(['car']),
);
