import React, { PropsWithChildren, useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { useTranslation } from 'react-i18next';
import { ColoredButton, useAppSearchParams } from '@japieglobal/shared/src/components';
import { largeNumberFormatter } from '@japieglobal/shared/src/utils';
import { UserContext } from '@japieglobal/shared/src/user-context';

const Price = ({ children }: PropsWithChildren) => (
  <Typography sx={{ fontSize: 12, textAlign: 'center' }}>{children}</Typography>
);
interface CarWindowPriceCellProps {
  car: CarResult;
  removeRanking: () => void;
}

export const CarWindowPriceCell = React.memo(({ car, removeRanking }: CarWindowPriceCellProps) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [searchParams] = useAppSearchParams();
  const { show_price_exex } = searchParams;

  return (
    <>
      {car.rank?.target_value && car.price?.recalculate && car.price?.recalculate.length > 0 ? (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Price>{largeNumberFormatter(user, car.rank?.target_value)}</Price>
          <Price>{`(${largeNumberFormatter(user, car.rank.target_discount)})`}</Price>
          <ColoredButton style={{ height: '20px', fontSize: '10px' }} type="button" onClick={removeRanking}>
            {t('CANCEL')}
          </ColoredButton>
        </Box>
      ) : (
        <>
          {largeNumberFormatter(
            user,
            show_price_exex
              ? car.price?.local_exex ?? car.rank?.target_value_exex
              : car.price?.local ?? car.rank?.target_value,
          )}
        </>
      )}
    </>
  );
});
