import { getCurrencies } from '@japieglobal/shared/src/api';
import { AppSearchParamKeys, SelectFilter, SelectOption } from '@japieglobal/shared/src/components';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import React, { useEffect, useState } from 'react';

export const TaxationFilterCurrencyConverter = () => {
  const [currencies, setCurrencies] = useState<string[]>([]);
  const { snackbarErrorHandler } = useSnackbarErrorHandler();

  useEffect(() => {
    getCurrencies().then(setCurrencies).catch(snackbarErrorHandler);
  }, [snackbarErrorHandler]);

  const options: SelectOption[] = currencies.map(currency => ({ value: currency, label: currency }));
  return <SelectFilter searchParamKey={AppSearchParamKeys.CURRENCY} labelTranslationKey="CURRENCY" staticOptions={options} />;
};
