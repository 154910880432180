import React from 'react';
import { Typography, Stack, Grid2 } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import { PageContainer } from '@japieglobal/shared/src/components';
import { Videos } from './videos';
import { VideoExplanation } from './video-explanation';

export const VideoExplanations = () => {
  return (
    <PageContainer
      hideResetAllButton
      internal
      mainComponent={
        <Grid2 container spacing={2} mt={4}>
          <Grid2 size={12} mb={4}>
            <Stack direction="row" alignItems="center" gap={1}>
              <SchoolIcon fontSize="large" />
              <Typography sx={{ fontSize: '40px' }}>Veelgestelde vragen</Typography>
            </Stack>

            <Typography>Uitleg over de verschillende onderwerpen binnen JP.cars</Typography>
          </Grid2>

          {Object.keys(Videos).map((video) => (
            <VideoExplanation key={video} video={video} />
          ))}
        </Grid2>
      }
    />
  );
};
