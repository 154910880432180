import { FormControlLabel, FormLabel, Switch } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import PersonIcon from '@mui/icons-material/Person';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MuiTelInput } from 'mui-tel-input';
import { hasPermission, isSuperAdmin as isSuperAdminUser } from '@japieglobal/shared/src/utils';
import {
  ColoredButton,
  ColoredOutlinedButton,
  NumberInput,
  ProgressIndicator,
} from '@japieglobal/shared/src/components';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { AdminTextField, FieldWrapper, FormRow } from '../shared/styled';
import { AdminMainWrapper } from '../admin-main-wrapper';
import { AdminEditType, AdminQueryStateType } from '../hooks/admin-query-state';
import { AdminUserDealerEdit } from './admin-user-dealer-edit';
import { AdminUserPermissions } from './admin-user-permissions';
import { AdminUserSuperPassword } from './admin-user-super-password';
import { AdminUserNotifications } from './admin-user-notifications';
import { isValidPhoneNumber } from '../../utils/validate-phone-number';
import { settings } from '@japieglobal/shared/src/settings';
import { forgotPassword, User, UserPermission, UserRole, userSendVerificationMail } from '@japieglobal/shared/src/api';
import { UserContext } from '@japieglobal/shared/src/user-context';

const useStyles = makeStyles(() => ({
  fixTextField: {
    '& .MuiInputBase-root': {
      backgroundColor: 'inherit',
    },
  },
  paddingLeft: {
    paddingLeft: '20px',
  },
}));

export interface AdminUserFormProps {
  title: string;
  editUser: Partial<User>;
  updateEditUser: <K extends keyof Partial<User>>(value: Partial<User>[K], key: K) => void;
  adminQueryState: AdminQueryStateType;
  handleSubmit: (event: React.FormEvent) => void;
  handleCancel: () => void;
  isLoading?: boolean;
  permissions: UserPermission[];
  dealers: { id: string; locations: string[] }[];
  setDealers: (val: { id: string; locations: string[] }[]) => void;
  handleChangeQuota: (v: number | undefined) => void;
}

export const AdminUserForm = ({
  title,
  editUser,
  updateEditUser,
  adminQueryState,
  handleSubmit,
  handleCancel,
  isLoading,
  permissions,
  dealers,
  setDealers,
  handleChangeQuota,
}: AdminUserFormProps) => {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const isSuperAdmin = isSuperAdminUser(user);
  const { userId, editType } = adminQueryState;
  const isScrape = editType === AdminEditType.SCRAPE_USER;
  const { t } = useTranslation();
  const isServiceAccount = editType === AdminEditType.SERVICE_ACCOUNTS;
  const { first_name, last_name, email, phone_number, role, two_factor_authentication, is_key, notifications, quotas } =
    editUser;
  const { snackbarErrorHandler, snackbarSuccessMessage } = useSnackbarErrorHandler();

  const handleSendVerification = () => {
    if (userId) {
      userSendVerificationMail({ userId })
        .then(() => snackbarSuccessMessage('Verificatiemail is verstuurd.'))
        .catch(snackbarErrorHandler);
    }
  };

  const handleSendResetPassword = () => {
    if (email) {
      forgotPassword({ email })
        .then(() => snackbarSuccessMessage('Reset password mail is verstuurd.'))
        .catch(snackbarErrorHandler);
    }
  };

  return (
    <AdminMainWrapper title={title}>
      <div />
      <div style={{ width: '900px', padding: '20px', fontSize: '16px' }} className={classes.fixTextField}>
        <form>
          {isSuperAdmin && (
            <FormRow>
              {editUser.created_at && (
                <div>
                  <FormLabel>
                    {t('CREATED_AT')} {new Date(editUser.created_at!).toLocaleDateString()}
                  </FormLabel>
                </div>
              )}
              {editUser.created_by && (
                <div>
                  <FormLabel>
                    {t('CREATED_BY')} {editUser.created_by}
                  </FormLabel>
                </div>
              )}
            </FormRow>
          )}
          <FormRow>
            <FieldWrapper>
              <PersonIcon />
              <AdminTextField
                style={{ marginLeft: '10px' }}
                fullWidth
                required
                placeholder={isServiceAccount ? 'API User label' : t('FIRST_NAME')}
                value={first_name}
                onChange={({ target }) => updateEditUser(target.value, 'first_name')}
              />
            </FieldWrapper>
            {!isServiceAccount && (
              <FieldWrapper className={classes.paddingLeft}>
                <PersonIcon />
                <AdminTextField
                  style={{ marginLeft: '10px' }}
                  fullWidth
                  required
                  placeholder={t('LAST_NAME')}
                  value={last_name}
                  onChange={({ target }) => updateEditUser(target.value, 'last_name')}
                />
              </FieldWrapper>
            )}
          </FormRow>
          {!isServiceAccount && (
            <>
              <FormRow>
                <FieldWrapper>
                  <MailIcon />
                  <AdminTextField
                    style={{ marginLeft: '10px' }}
                    type="email"
                    fullWidth
                    required
                    placeholder="Email"
                    value={email}
                    onChange={({ target }) => updateEditUser(target.value, 'email')}
                  />
                </FieldWrapper>
                {isSuperAdmin && (
                  <FieldWrapper className={classes.paddingLeft}>
                    <PhoneIcon />
                    <MuiTelInput
                      style={{ marginLeft: '10px' }}
                      value={phone_number}
                      onChange={(value) => updateEditUser(value.replaceAll(' ', ''), 'phone_number')}
                      defaultCountry={settings().country.toUpperCase()}
                      error={!isValidPhoneNumber(phone_number)}
                    />
                  </FieldWrapper>
                )}
              </FormRow>
              {isSuperAdmin && (
                <FormRow>
                  <FieldWrapper>
                    <div>
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            checked={two_factor_authentication ?? true}
                            onChange={({ target }) => updateEditUser(target.checked, 'two_factor_authentication')}
                            name="enable2FA"
                          />
                        }
                        label="Enable 2 factor authentication"
                      />
                    </div>
                  </FieldWrapper>
                </FormRow>
              )}
              {isSuperAdmin && (
                <FormRow>
                  <FieldWrapper>
                    <div>
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            checked={role === UserRole.ADMIN}
                            onChange={({ target }) =>
                              updateEditUser(target?.checked ? UserRole.ADMIN : UserRole.USER, 'role')
                            }
                            name="isAdmin"
                          />
                        }
                        label="Admin"
                      />
                    </div>
                    <div>
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            checked={is_key ?? false}
                            onChange={({ target }) => updateEditUser(target.checked, 'is_key')}
                            name="isKey"
                          />
                        }
                        label="Key User"
                      />
                    </div>
                  </FieldWrapper>
                </FormRow>
              )}
            </>
          )}
          {!isScrape && (
            <FormRow>
              <AdminUserDealerEdit dealers={dealers} setDealers={setDealers} />
            </FormRow>
          )}
          {(!isServiceAccount || hasPermission(user, UserPermission.SYSTEM_ADMIN)) && (
            <>
              <FormRow>
                <AdminUserPermissions
                  permissions={permissions}
                  setPermissions={(permissions) => updateEditUser(permissions, 'permissions')}
                  isScrape={isScrape}
                  isServiceAccount={isServiceAccount}
                  editUser={editUser}
                />
              </FormRow>
              {!isScrape && !isServiceAccount && (
                <FormRow>
                  <AdminUserNotifications
                    role={editUser.role}
                    notifications={notifications || []}
                    setNotifications={(nofitcations) => updateEditUser(nofitcations, 'notifications')}
                  />
                </FormRow>
              )}
            </>
          )}
          {isSuperAdmin && !isScrape && (
            <FormRow>
              <FieldWrapper>
                <NumberInput min={0} label="API Quota" value={quotas?.api} setValue={handleChangeQuota} />
              </FieldWrapper>
              <FieldWrapper>
                <NumberInput
                  min={0}
                  max={5}
                  label={'Minimal APR'}
                  value={editUser.minimal_apr}
                  setValue={(v) => {
                    if (v) updateEditUser(v, 'minimal_apr');
                  }}
                />
              </FieldWrapper>
            </FormRow>
          )}
          {editUser.is_service_account && editUser.token && (
            <FormRow>
              <div>
                <strong>Token</strong>: {editUser.token}
              </div>
            </FormRow>
          )}
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ColoredOutlinedButton onClick={handleCancel}>{t('CANCEL')}</ColoredOutlinedButton>
            <ColoredButton style={{ marginLeft: '10px' }} onClick={handleSubmit}>
              {t('SAVE')}
            </ColoredButton>
          </div>
          {editUser?.id && editUser.is_verified !== true && (
            <div style={{ display: 'flex' }}>
              <ColoredButton onClick={handleSendVerification}>{t('SEND_VERIFICATION_MAIL')}</ColoredButton>
            </div>
          )}
          {isSuperAdmin && editUser?.id && editUser.is_verified === true && (
            <div style={{ display: 'flex' }}>
              <ColoredButton onClick={handleSendResetPassword}>{t('SEND_PASSWORD_RESET_MAIL')}</ColoredButton>
            </div>
          )}
        </form>
        {editUser?.id && isSuperAdmin && !editUser.is_service_account && (
          <AdminUserSuperPassword editUser={editUser} setEditUser={updateEditUser} />
        )}
      </div>

      {isLoading && <ProgressIndicator />}
    </AdminMainWrapper>
  );
};
