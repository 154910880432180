import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { ResultTableRow } from './result-table-row';
import { CarTableCell } from './result-table-styles';
import { AppSearchParamKeys, useFilterGroup } from '@japieglobal/shared/src/components';
import { settings } from '@japieglobal/shared/src/settings';

interface ResultsTableProps {
  cars: CarResult[];
  reloadCarList: (id: number) => void;
}
export const ResultsTable = React.memo(({ cars, reloadCarList }: ResultsTableProps) => {
  useFilterGroup([
    AppSearchParamKeys.SORT_TURNOVER_EXT,
    AppSearchParamKeys.SORT_TURNOVER_INT,
    AppSearchParamKeys.SORT_APR,
    AppSearchParamKeys.SORT_CLICKS,
    AppSearchParamKeys.SORT_LEADS,
    AppSearchParamKeys.SORT_STOCK_DAYS,
    AppSearchParamKeys.SORT_STOCK_DAYS_CLIENT,
  ]);

  const { t } = useTranslation();

  const tableRows = useMemo(
    () => cars.map((car) => <ResultTableRow key={car.id} car={car} reloadCarList={reloadCarList} />),
    [cars, reloadCarList],
  );

  const tableBody = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <TableBody name="car-results">{tableRows}</TableBody>;
  }, [tableRows]);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <CarTableCell>{t('PHOTO')}</CarTableCell>
            <CarTableCell>{t('BRAND_MODEL')}</CarTableCell>
            <CarTableCell>{t('INFO')}</CarTableCell>
            <CarTableCell>{t('ESTABLISHMENT')}</CarTableCell>
            <CarTableCell>{settings().showLicensePlate ? t('LICENSE_PLATE') : t('REFERENCE_CODE')}</CarTableCell>
            <CarTableCell>{t('WINDOW')}</CarTableCell>
            <CarTableCell>{t('WEBSITE')}</CarTableCell>
            <CarTableCell />
            <CarTableCell />
            <CarTableCell />
            <CarTableCell>{t('PRICING')}</CarTableCell>
            <CarTableCell />
            <CarTableCell />
          </TableRow>
        </TableHead>
        {tableBody}
      </Table>
    </TableContainer>
  );
});
