import { Dialog, DialogContent, DialogTitle, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/styles';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { reorderList } from '../../utils/reorder-list';
import { addCarBasedOnRank } from '../../utils/add-car-to-list-rank-based';
import { useDebouncedEffect } from '@japieglobal/shared/src/hooks/use-debounced-effect';
import { ProgressIndicator, useAppSearchParams } from '@japieglobal/shared/src/components';
import { PricingType } from '@japieglobal/shared/src/types/pricing-type';
import { CarQuery, carWindow, CarWindowResult } from '@japieglobal/shared/src/api';
import { TopDealer } from '@japieglobal/shared/src/types';
import { Pageable } from '@japieglobal/shared/src/types/pagination';
import { usePriceRankingStates } from '../../states/price-ranking';
import { ResultTableRow } from '../results/result-table-row';
import { TaxationErrorView } from '../../taxatie/taxation-list-view/taxation-error-view';
import { CarWindowTable } from '../../taxation-and-monitoring-shared/car-compare-table/car-compare-table';
import { CarWindowPageCounter } from '../../taxation-and-monitoring-shared/car-compare-table/car-compare-page-counter';
import { ClickState } from '@japieglobal/shared/src/types/click-state';

const InitialContent = styled(`div`)({ height: '200px' });

const StyledDialogContent = styled(DialogContent)({ flex: '0 0 auto' });

const HeaderTable = styled(`table`)({ width: '100%' });

interface CompetitorDialogProps {
  showDialog: boolean;
  setShowDialog: (val: boolean) => void;
  carId: number | undefined;
  reloadCarList: (id: number) => void;
}

export const CompetitorDialog = React.memo(
  ({ carId, showDialog, setShowDialog, reloadCarList }: CompetitorDialogProps) => {
    const [searchParams, setSearchParams] = useAppSearchParams();

    const { pricingTarget, priceDiscount, setPricingTarget, setPriceDiscount, resetPriceRanking } =
      usePriceRankingStates();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    const { snackbarErrorHandler } = useSnackbarErrorHandler();

    const [car, setCar] = useState<CarResult | undefined>(undefined);
    const [windowResult, setWindowResult] = useState<CarWindowResult | undefined>(undefined);
    const [competitorCars, setCompetitorCars] = useState<CarResult[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [pageable, setPageable] = useState<Pageable | undefined>(undefined);

    const [topDealers, setTopDealers] = useState<TopDealer[] | undefined>(undefined);

    const reloadCarListInDialog = useCallback(
      (id: number) => {
        resetPriceRanking();
        reloadCarList(id);
      },
      [reloadCarList, resetPriceRanking],
    );

    const changePage = useCallback(
      (pageIndex: number) => {
        resetPriceRanking();
        setSearchParams({ alternative_page_index: pageIndex });
      },
      [resetPriceRanking, setSearchParams],
    );

    const handleClose = useCallback(async () => {
      setShowDialog(false);
      resetPriceRanking();
      setSearchParams({
        click_own_supply: undefined,
        click_equipment_in_stock: undefined,
        click_equipment_is_dealer: undefined,
        click_equipment_official_make: undefined,
        click_stat_local_car: undefined,
        click_hp: undefined,
        click_model_year: undefined,
        click_build: undefined,
        include_banned: undefined,
        click_dealer: undefined,
        click_polygon: undefined,
        click_location: undefined,
        alternative_page_index: undefined,
        window_car_id: undefined,
        click_options: undefined,
        priceDiscount: undefined,
      });
    }, [resetPriceRanking, setSearchParams, setShowDialog]);

    useDebouncedEffect(
      () => {
        if (carId) {
          const queryProps: CarQuery = {
            window_car_id: carId,
            click_own_supply: searchParams.click_own_supply,
            click_equipment_in_stock: searchParams.click_equipment_in_stock as ClickState,
            click_equipment_is_dealer: searchParams.click_equipment_is_dealer,
            click_stat_local_car: searchParams.click_stat_local_car,
            click_equipment_official_make: searchParams.click_equipment_official_make,
            click_hp: searchParams.click_hp,
            click_model_year: searchParams.click_model_year,
            click_build: searchParams.click_build,
            include_banned: searchParams.include_banned,
            click_dealer: searchParams.click_dealer,
            click_polygon: searchParams.click_polygon,
            click_location: searchParams.click_location,
            click_options: JSON.stringify(searchParams.click_options),
            page_index:
              (pricingTarget !== undefined ? pageable?.page_index : null) || searchParams.alternative_page_index,
            page_size: 10,
          };
          if (priceDiscount !== undefined) {
            queryProps.pricing_target = priceDiscount;
            queryProps.pricing_type = PricingType.DISCOUNT;
          } else if (pricingTarget !== undefined) {
            queryProps.pricing_target = pricingTarget;
            queryProps.pricing_type = PricingType.POSITION;
          }

          setIsLoading(true);
          carWindow(queryProps)
            .then((res) => {
              if (res.car) {
                setCar(res.car);
                setCompetitorCars(addCarBasedOnRank(res.car, res.window, pageable?.page_size || 0));
                setTopDealers(res.top_dealers);
              }
              setWindowResult(res);
              setPageable(res.pagable);
            })
            .catch(snackbarErrorHandler)
            .finally(() => setIsLoading(false));
        }
      },
      500,
      [priceDiscount, pricingTarget, carId, searchParams],
    );

    const reorder = useCallback(
      (source: number, target: number) => {
        const newTarget = competitorCars[target]?.rank?.counter;

        setCompetitorCars((prevState) => {
          return reorderList(prevState, source, target);
        });
        if (newTarget !== undefined) {
          setPriceDiscount(undefined);
          setPricingTarget(newTarget);
        }
      },
      [competitorCars, setPriceDiscount, setPricingTarget],
    );

    const pageCount = pageable?.page_count ?? 0;

    const normalContent = car && (
      <>
        <StyledDialogContent dividers>
          <HeaderTable>
            <tbody>
              <ResultTableRow
                closeDialog={handleClose}
                car={car}
                reloadCarList={reloadCarListInDialog}
                isCompetitorIncluded
                windowView
                topDealers={topDealers}
              />
            </tbody>
          </HeaderTable>
        </StyledDialogContent>
        <DialogContent dividers>
          <CarWindowTable
            cars={competitorCars}
            ownCar={car}
            reorder={reorder}
            removeRanking={resetPriceRanking}
            taxationView={false}
            error={windowResult?.error}
          />
        </DialogContent>
        {car && pageCount > 0 && (
          <StyledDialogContent>
            <CarWindowPageCounter
              changePage={changePage}
              pageCount={pageCount}
              currentPage={pageable?.page_index || searchParams.alternative_page_index || 0}
            />
          </StyledDialogContent>
        )}
      </>
    );

    return (
      <Dialog
        fullScreen={fullScreen}
        maxWidth="lg"
        fullWidth
        onClose={handleClose}
        aria-labelledby="export"
        open={showDialog}
        disableEnforceFocus
      >
        <DialogTitle>{t('WINDOW')}</DialogTitle>
        {car ? (
          normalContent
        ) : (
          <InitialContent>{windowResult?.error && <TaxationErrorView windowResult={windowResult} />}</InitialContent>
        )}
        {isLoading && <ProgressIndicator />}
      </Dialog>
    );
  },
);
