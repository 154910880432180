import React from 'react';
import { MenuButton } from '../../menu-button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { User, UserPermission } from '../../../../api';
import { hasPermission } from '../../../../utils';

export const DownloadButton = ({ user }: { user: User }) => (
  <MenuButton
    display={hasPermission(user, UserPermission.RECOMMENDATION)}
    hoverTitle={'DOWNLOADS_PURCHASE'}
    link="/admin-api/purchase-download"
  >
    <FileDownloadIcon />
  </MenuButton>
);
