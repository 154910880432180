import React, { useEffect, useMemo, useState } from 'react';
import { ColoredBorderButton, LoadingWrapper, TextInput, useAppSearchParams } from '@japieglobal/shared/src/components';
import { CarHeader, CarHeaderProps, carHeader } from '@japieglobal/shared/src/api';
import { Grid2 } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TAXATION_STEP_ORDER } from './taxation-steps-view';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { OptionIcon, optionIcons } from '@japieglobal/shared/src/components';

interface TaxationStepProps {
  inputKey: CarHeader;
  goToNextStep: () => void;
}

export const TaxationStep = ({ inputKey, goToNextStep }: TaxationStepProps) => {
  const [searchParams, setSearchParams] = useAppSearchParams();
  const [allOptions, setOptions] = useState<(string | number)[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [optionFilter, setOptionFilter] = useState('');
  const { snackbarErrorHandler } = useSnackbarErrorHandler();

  const carHeaderOptions = useMemo(() => {
    const carHeaderProps: CarHeaderProps = { dealer_specific: false, type: inputKey as unknown as CarHeader };
    for (let i = 0; i < TAXATION_STEP_ORDER.indexOf(inputKey); i++) {
      carHeaderProps[TAXATION_STEP_ORDER[i]] = searchParams[TAXATION_STEP_ORDER[i]];
    }
    return carHeaderProps;
  }, [inputKey, searchParams]);
  const selectedOptions = searchParams[inputKey];

  const { t } = useTranslation();

  useEffect(() => {
    if (allOptions.length < 1) {
      setIsLoading(true);
      carHeader(carHeaderOptions)
        .then((res) => setOptions(res.headers))
        .catch(snackbarErrorHandler)
        .finally(() => setIsLoading(false));
    }
  }, [allOptions.length, carHeaderOptions, snackbarErrorHandler]);

  const onClickItem = (option: string | number) => {
    let valueToSet: string | string[] | number | undefined = undefined;
    if (inputKey === CarHeader.OPTIONS) {
      const stringOption = option as string;
      if (Array.isArray(selectedOptions)) {
        if (selectedOptions?.includes(stringOption)) {
          selectedOptions.splice(selectedOptions.indexOf(stringOption), 1);
          valueToSet = selectedOptions;
        } else {
          valueToSet = [...selectedOptions, stringOption];
        }
      } else {
        valueToSet = [stringOption];
      }
    } else {
      if (selectedOptions !== option) {
        valueToSet = option;
        goToNextStep();
      } else {
        valueToSet = undefined;
      }
    }
    setSearchParams({ ...searchParams, [inputKey]: valueToSet });
  };

  return (
    <LoadingWrapper isLoading={isLoading}>
      <Grid2 container rowSpacing={1}>
        <Grid2>
          <TextInput value={optionFilter} label="filter" setValue={setOptionFilter} />
        </Grid2>
        <Grid2 spacing={'10px'} container>
          {(optionFilter
            ? allOptions.filter((option) => t(String(option)).toUpperCase().includes(optionFilter.toUpperCase()))
            : allOptions
          )?.map((option) => (
            <Grid2 key={option}>
              <ColoredBorderButton
                startIcon={
                  optionIcons[option] &&
                  inputKey === CarHeader.OPTIONS && <OptionIcon color="inherit" isEnabled option={option as string} />
                }
                onClick={() => onClickItem(option)}
                color={
                  Array.isArray(selectedOptions)
                    ? selectedOptions.includes(option as string)
                      ? 'primary'
                      : 'secondary'
                    : selectedOptions === option
                      ? 'primary'
                      : 'secondary'
                }
              >
                {inputKey === CarHeader.OPTIONS && typeof option === 'string'
                  ? t((option as string).toUpperCase())
                  : option}
              </ColoredBorderButton>
            </Grid2>
          ))}
        </Grid2>
      </Grid2>
    </LoadingWrapper>
  );
};
