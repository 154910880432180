import { ClassificationModel, getClassificationModels, removeModel } from '@japieglobal/shared/src/api';
import { BaseTable, ColoredButton, ConfirmationDialog, StringAppSearchParam, TableColDef, TextFilter, Tooltip, TruncatedText, useAppSearchParams } from '@japieglobal/shared/src/components';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import AddIcon from '@mui/icons-material/Add';
import DatasetIcon from '@mui/icons-material/Dataset';
import DeleteIcon from '@mui/icons-material/Delete';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Grid2, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SuperAdminPageContainer } from '../../super-admin-page-container';
import { DatasetDialog, DetectionDialog, TrainDialog } from './photo-detector-actions';


export const PhotoDetectorManagement = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [models, setModels] = useState<ClassificationModel[]>([]);
  const [model, setModel] = useState<ClassificationModel | undefined>();
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(50);
  const [total, setTotal] = useState<number | undefined>();
  const [openDetection, setOpenDetection] = useState<boolean>(false);
  const [openDataset, setOpenDataset] = useState<boolean>(false);
  const [openTrain, setOpenTrain] = useState<boolean>(false);
  const [showRemoveDialog, setShowRemoveDialog] = useState<boolean>(false);
  const { snackbarErrorHandler, snackbarSuccessMessage } = useSnackbarErrorHandler();
  const [searchParams] = useAppSearchParams();

  useEffect(() => {
    setIsLoading(true);
    getClassificationModels({ model: searchParams.model, page, size })
      .then((result) => {
        setModels(result.items);
        setTotal(result.total);
      }).catch(snackbarErrorHandler)
      .finally(() => setIsLoading(false));
  }, [page, size, snackbarErrorHandler, searchParams.model]);

  const formatPercent = (value: number) => Math.round(value * 1000) / 10;

  const deleteModel = (model: ClassificationModel) => {
    removeModel(model.model, model.attr).then(
      () => snackbarSuccessMessage('Successfully removed')
    ).catch(snackbarErrorHandler).finally(() => setShowRemoveDialog(false));
  }

  const columns: TableColDef<ClassificationModel>[] = [
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
    },
    {
      field: 'model',
      headerName: 'Model',
      flex: 2,
    },
    {
      field: 'attr',
      headerName: 'Attribute',
      flex: 2,
    },
    {
      field: 'possible_values',
      headerName: 'Possible Values',
      flex: 4,
      isTruncated: true,
      renderCell: (params) => <Tooltip onHoverContent={(params.value || []).map((v) => <div>{v}</div>)}>
        <TruncatedText>{(params.value || []).join(", ")}</TruncatedText>
      </Tooltip>,
    },
    {
      field: 'val_acc',
      headerName: 'Accuracy',
      flex: 1,
      renderCell: (params) => <div>{formatPercent(params.value)}%</div>,
    },
    {
      field: 'id',
      headerName: 'Actions',
      flex: 2,
      renderCell: (params) => <div>
        <IconButton title="dataset" onClick={() => { setModel(params.row); setOpenDataset(true) }}><DatasetIcon /></IconButton>
        <IconButton title="detect" onClick={() => { setModel(params.row); setOpenDetection(true) }}><PhotoLibraryIcon /></IconButton>
        <IconButton title="train" onClick={() => { setModel(params.row); setOpenTrain(true) }}><RefreshIcon /></IconButton>
        <IconButton title="remove" onClick={() => { setModel(params.row); setShowRemoveDialog(true) }}><DeleteIcon /></IconButton>
      </div>,
    },
  ];

  return (
    <SuperAdminPageContainer>
      {showRemoveDialog && (
        <ConfirmationDialog
          closeDialog={() => setShowRemoveDialog(false)}
          noAction={() => setModel(undefined)}
          question="Do you want to remove the photo detection model?"
          title="Remove photo detection model?"
          yesAction={() => model && deleteModel(model)}
        />
      )}

      {openTrain && <TrainDialog open={openTrain} onClose={() => { setOpenTrain(false); setModel(undefined) }} initialParams={{ model: model?.model, attr: model?.attr }} />}
      {openDetection && !!model && <DetectionDialog open={openDetection} onClose={() => { setOpenDetection(false); setModel(undefined) }} model={model} />}
      {openDataset && !!model && <DatasetDialog open={openDataset} onClose={() => { setOpenDataset(false); setModel(undefined) }} model={model.model} attr={model.attr} />}

      <Grid2 container spacing={2} mt={5} mb={1}>
        <Grid2 size={2}>
          <TextFilter
            hideResetButton
            labelTranslationKey={'MODEL'}
            searchParamKey={StringAppSearchParam.MODEL}
          />
        </Grid2>
        <Grid2 size={2}>
          <ColoredButton onClick={() => { setOpenTrain(true) }}><AddIcon /></ColoredButton>
        </Grid2>
      </Grid2>

      <BaseTable
        isLoading={isLoading}
        rows={models}
        columns={columns}
        getRowId={(row) => row.id}
        headerHeight={55}
        rowHeight={35}
        hideFooter={false}
        paginationModel={{
          pageSize: size, pageIndex: page - 1, rowCount: total || 0, paginationMode: "server", pageSizeOptions: [10, 50, 100],
          onPaginationModelChange: (model) => {
            setPage(model.page + 1);
            setSize(model.pageSize);
          },
        }}
      />
    </SuperAdminPageContainer>
  );
};
