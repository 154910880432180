import { ColoredButton, Tooltip, useAppSearchParams } from '@japieglobal/shared/src/components';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Dialog, DialogActions, DialogContent, Grid2, useTheme } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import { TaxationsTable } from './taxations-table';
import { getSavedTaxation, SavedTaxation, SavedTaxationQuery, saveTaxation } from '@japieglobal/shared/src/api';
import { CarResult } from '@japieglobal/shared/src/types';
import { ColoredButtonLargeText } from '../monitoring/results/result-table-styles';
import { useSearchParams } from 'react-router-dom';
import { getMoney } from '@japieglobal/shared/src/utils';
import { UserContext } from '@japieglobal/shared/src/user-context';

const IconButton = styled(ColoredButtonLargeText)({
  width: '35px',
  height: '30px',
  fontSize: '15px',
});

const useStyles = makeStyles({
  general: {
    width: '100%',
    boxSizing: 'border-box',
    marginTop: '6px',
    marginBottom: '16px',
    resize: 'vertical',
  },
  rounded: {
    padding: '12px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
});

const SpaceBetweenGrid = ({ label, value }: { label: string; value: React.ReactNode }) => {
  const { t } = useTranslation();

  return (
    <Grid2 container justifyContent={'space-between'}>
      <span>{t(label)}:</span>
      {value}
    </Grid2>
  );
};

interface SaveTaxationDialogProps {
  car: CarResult;
  showDialog: boolean;
  setShowDialog: (val: boolean) => void;
  setTaxations: any;
}
const SaveTaxationDialog = ({ car, showDialog, setShowDialog, setTaxations }: SaveTaxationDialogProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const [description, setDescription] = useState<string | undefined>();
  const classes = useStyles();
  const handleClose = useCallback(() => setShowDialog(false), [setShowDialog]);
  const [{ extra_costs, extra_margin }] = useAppSearchParams();
  const [searchParams] = useSearchParams();
  const { user } = useContext(UserContext);
  const onSubmit = useCallback(() => {
    saveTaxation(
      car,
      description,
      searchParams.get('click_options'),
      searchParams.get('click_equipment_in_stock'),
      searchParams.get('extra_costs'),
      searchParams.get('extra_margin'),
    )
      .then((res) => {
        setTaxations((taxations: SavedTaxation[]) => [res].concat(taxations));
      })
      .finally(() => handleClose());
  }, [car, description, searchParams, setTaxations, handleClose]);

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth="sm"
      fullWidth
      onClose={handleClose}
      aria-labelledby="export"
      open={showDialog}
      disableEnforceFocus
    >
      <DialogTitle>{t('SAVE_TAXATION')}</DialogTitle>
      <DialogContent dividers>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <img height="80px" style={{ marginRight: '10px' }} alt="car" src={car.image} />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h4>
              {car.make}&nbsp;{car.model}&nbsp;
              {getMoney(user, car.rank?.target_value)}
              <div>{car.license_plate}</div>
            </h4>
            <SpaceBetweenGrid label="VVP_GROSS" value={<span>{getMoney(user, car.rank?.target_value)}</span>} />
            {car.topdown_value && (
              <SpaceBetweenGrid label="TDC_GROSS" value={<span>{getMoney(user, car.topdown_value)}</span>} />
            )}
            {!!extra_costs && (
              <SpaceBetweenGrid label="EXTRA_COSTS" value={<span>{getMoney(user, extra_costs)}</span>} />
            )}
            {!!extra_margin && (
              <SpaceBetweenGrid label="EXTRA_MARGIN" value={<span>{getMoney(user, extra_margin)}</span>} />
            )}
          </div>
          <div style={{ flexGrow: 1 }} />
        </div>
        <form onSubmit={onSubmit} style={{ padding: '5px' }}>
          <span>{t('NOTE')}</span>
          <textarea
            className={`${classes.rounded} ${classes.general}`}
            rows={5}
            placeholder={t('OPTIONAL')}
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <ColoredButton onClick={onSubmit}>{t('SEND')}</ColoredButton>
      </DialogActions>
    </Dialog>
  );
};

export const SaveTaxation = React.memo(({ car }: { car?: CarResult }) => {
  const [taxations, setTaxations] = useState<SavedTaxation[] | undefined>();
  const [showDialog, setShowDialog] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!car?.dealer?.name) return;
    const params: SavedTaxationQuery = {
      license_plate: car.license_plate,
      make: car.make,
      model: car.model,
      fuel: car.fuel,
      gear: car.gear,
      body: car.body,
      build: car.build,
      mileage: car.mileage,
    };
    getSavedTaxation(car.dealer.name, params).then((res) => {
      setTaxations(res.results);
    });
  }, [
    car?.dealer,
    car?.license_plate,
    car?.make,
    car?.model,
    car?.fuel,
    car?.gear,
    car?.body,
    car?.build,
    car?.mileage,
  ]);

  return (
    <>
      {!!taxations?.length && (
        <Tooltip
          onHoverContent={<TaxationsTable taxations={taxations} hoverTable />}
          placement="bottom-end"
          interactive
        >
          <IconButton disabled={!car} style={{ color: 'white', backgroundColor: 'red' }}>
            <NotificationsIcon />
          </IconButton>
        </Tooltip>
      )}
      <IconButton onClick={() => setShowDialog(true)} title={t('SAVE_TAXATION')} className="saveTaxationButton">
        <CloudUploadOutlinedIcon />
      </IconButton>
      {showDialog && car && (
        <SaveTaxationDialog
          car={car}
          showDialog={showDialog}
          setShowDialog={setShowDialog}
          setTaxations={setTaxations}
        />
      )}
    </>
  );
});
