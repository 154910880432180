export const getProtocolAndDomain = () => {
  if (typeof document !== 'undefined') {
    // we are running on web
    const { origin } = window.location;
    if (origin.includes('localhost')) {
      return process.env.REACT_APP_BACKEND_HOST || 'https://api.nl.jpcars.dev';
    }
    return origin.replace('://', '://api.');
  }
  // we are running on react-native
  return `https://api.jp.cars`;
};

export const isJavaDomain = () => {
  if (typeof document !== 'undefined') {
    // we are running on web
    const { origin } = window.location;
    return origin.includes('java.jp.cars');
  }
  // we are running on react-native
  return false;
};
