import { Paper } from '@mui/material';
import React, { useMemo } from 'react';
import { ExportPartialView } from './export-view';
import { ResultsTable } from './results/results-table';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { CarsEmptyState } from './cars-empty-state';
import { CarsLoadMoreButton } from './cars-load-more-button';
import { CarMessageMarkAsAllAsReadButton } from './car-message/car-message-mark-as-all-as-read-button';
import { Filter } from '../filter/filter';
import { useSearchParams } from 'react-router-dom';
import { Pageable } from '@japieglobal/shared/src/types/pagination';
import { PageContainer, ProgressIndicator } from '@japieglobal/shared/src/components';

interface CarMainNormalViewProps {
  cars: CarResult[];
  loading: boolean;
  handleGetMoreResults: () => void;
  pageable: Pageable | undefined;
  reloadCarList: (id: number) => void;
  resetFilters: () => void;
}

export const CarMainNormalView = React.memo(
  ({ cars, loading, handleGetMoreResults, pageable, reloadCarList, resetFilters }: CarMainNormalViewProps) => {
    const hasMore = pageable && (pageable.page_index + 1) * pageable.page_size < pageable.total;
    const [searchParams] = useSearchParams();
    const showingMessages = !!searchParams.get('show_car_messages');
    const filterComponent = useMemo(() => <Filter />, []);
    const mainComponent = useMemo(
      () => (
        <>
          {showingMessages && <CarMessageMarkAsAllAsReadButton />}
          <Paper style={{ position: 'relative' }}>
            {cars?.length > 0 && <ResultsTable cars={cars} reloadCarList={reloadCarList} />}
            {loading ? <ProgressIndicator /> : cars?.length === 0 && <CarsEmptyState />}
          </Paper>
          {hasMore && <CarsLoadMoreButton handleGetMoreResults={handleGetMoreResults} />}
        </>
      ),
      [showingMessages, cars, reloadCarList, loading, hasMore, handleGetMoreResults],
    );

    return (
      <PageContainer
        onClickReset={resetFilters}
        internal
        mainExtraButtons={!showingMessages ? [<ExportPartialView key={'export'} />] : undefined}
        filterCount={pageable?.total}
        leftMenuComponent={filterComponent}
        mainComponent={mainComponent}
      />
    );
  },
);
