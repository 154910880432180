import React, { useCallback, useContext, useEffect, useState } from 'react';
import { SalesReportTable } from './table/sales-report-table';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { IconButton, Dialog, DialogContent } from '@mui/material';
import { DealersSelect } from '@japieglobal/shared/src/components';
import {
  CsmReportKey,
  dealerSearch,
  getSalesReport,
  SalesReportRow,
  updateDealerAccount,
} from '@japieglobal/shared/src/api';
import { UserContext } from '@japieglobal/shared/src/user-context';
import { ConfirmationDialog, useAppSearchParams } from '@japieglobal/shared/src/components';
import { handleUpdateDealerAccount, UpdateDealerAccountFuncType } from '../shared/csm-and-sales-report/types';

export const AdminSalesReport = () => {
  const [showDealerSelectModal, setShowDealerSelectModal] = useState(false);
  const { snackbarErrorHandler } = useSnackbarErrorHandler();
  const { user } = useContext(UserContext);
  const [salesReportRows, setSalesReportRows] = useState<SalesReportRow[]>([]);
  const [searchParams, setSearchParams] = useAppSearchParams();
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSelect = (dealer?: string) => {
    if (dealer) {
      updateDealerAccount({ name: dealer })
        .then(getSalesReportRows)
        .catch(snackbarErrorHandler)
        .finally(() => setShowDealerSelectModal(false));
    }
  };

  const getSalesReportRows = useCallback(() => {
    setIsLoading(true);
    getSalesReport()
      .then((res) => {
        // Adding the last comment to the rows like this because it was the fasted solution for now.
        setSalesReportRows(
          res.map((row) => ({
            ...row,
            [CsmReportKey.LAST_COMMENT_DATE]:
              row?.[CsmReportKey.COMMENTS]?.[row[CsmReportKey.COMMENTS]?.length - 1].created_at,
          })),
        );
      })
      .finally(() => setIsLoading(false));
  }, []);

  const handleUpdateDealer: UpdateDealerAccountFuncType = ({ row, key, value }) => {
    setIsLoading(true);
    handleUpdateDealerAccount({ row, key, value }).then(getSalesReportRows).catch(snackbarErrorHandler);
  };

  useEffect(() => {
    getSalesReportRows();
  }, [getSalesReportRows]);

  useEffect(() => {
    if (
      searchParams.dealer &&
      salesReportRows.length > 0 &&
      !salesReportRows.find((row) => row[CsmReportKey.DEALER] === searchParams.dealer)
    ) {
      dealerSearch({ name: searchParams.dealer })
        .then((res) => {
          if (searchParams.dealer && res.results.map((i) => i.name).includes(searchParams.dealer)) {
            setShowAddDialog(true);
          }
        })
        .catch(snackbarErrorHandler);
    }
  }, [salesReportRows, searchParams.dealer, snackbarErrorHandler]);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '24px' }}>
        <IconButton onClick={() => setShowDealerSelectModal(true)}>
          <AddCircleIcon color="primary" fontSize="large" />
        </IconButton>
      </div>
      {showAddDialog && (
        <ConfirmationDialog
          closeDialog={() => setShowAddDialog(false)}
          noAction={() => setSearchParams({ dealer: undefined })}
          question="This dealer does not exist yet, do you want to add it?"
          title="Add Dealer?"
          yesAction={() => handleSelect(searchParams.dealer)}
        />
      )}
      <Dialog open={showDealerSelectModal} onClose={() => setShowDealerSelectModal(false)}>
        <DialogContent sx={{ width: 300 }}>
          <DealersSelect user={user} onSelect={handleSelect} />
        </DialogContent>
      </Dialog>
      <SalesReportTable
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        salesReportRows={salesReportRows}
        onDealerUpdated={handleUpdateDealer}
        getSalesReportRows={getSalesReportRows}
      />
    </>
  );
};
