import React, { useMemo } from 'react';
import { MultiSelectFilter, useAppSearchParams, AppSearchParamKeys } from '@japieglobal/shared/src/components';
import { CarHeader, CarHeaderProps } from '@japieglobal/shared/src/api';

export const FilterModel = () => {
  const [{ makes }] = useAppSearchParams([AppSearchParamKeys.MAKES]);
  const carHeaderOptions: CarHeaderProps = useMemo(() => ({ type: CarHeader.MODEL, makes }), [makes]);
  const filterDependencies = useMemo(() => ({ [AppSearchParamKeys.MAKES]: makes }), [makes]);

  return (
    <MultiSelectFilter
      searchParamKey={AppSearchParamKeys.MODELS}
      carHeaderOptions={carHeaderOptions}
      filterDependencies={filterDependencies}
      labelTranslationKey="MODEL"
      disableCloseOnSelect
    />
  );
};
