import React from 'react';
import { Box, styled } from '@mui/material';
import FlagIcon from '@mui/icons-material/Flag';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { formatDateAsDate } from '@japieglobal/shared/src/utils';
import { BoxFlexColumn, CarTableCell } from '../result-table-styles';
import { ClickState } from '@japieglobal/shared/src/types/click-state';
import { OptionIcon } from '@japieglobal/shared/src/components';

const CarTableCellStyled = styled(CarTableCell)({});
const PositionWrapper = styled(`div`)({ width: '125px', display: 'flex', flexDirection: 'column' });
const PositionTop = styled(`div`)({ flex: '2' });
const SoldDate = styled(`div`)({ fontSize: '11px', display: 'flex', justifyContent: 'center' });
const FlagStyled = styled(FlagIcon)({ height: '15px' });

function getSoldDate(car: CarResult) {
  if (car.sold?.since && car.sold?.since > 0) {
    const d = new Date();
    d.setDate(d.getDate() - car.sold?.since);
    return formatDateAsDate(d);
  }
  return undefined;
}

export const ResultBrandModel = React.memo(({ car, windowView }: { car: CarResult; windowView?: boolean }) => {
  const soldDate = getSoldDate(car);

  return (
    <CarTableCellStyled>
      <BoxFlexColumn>
        <PositionWrapper>
          <PositionTop>
            <div>{car.make}</div>
            <div>{car.model}</div>
          </PositionTop>
          {soldDate && (
            <SoldDate>
              <FlagStyled /> {soldDate}
            </SoldDate>
          )}
        </PositionWrapper>
        {windowView && car?.standard_options?.length ? (
          <Box display="flex" gap={0.5}>
            {car.standard_options?.map((option) => {
              const isMissing = !car.extracted_options?.includes(option);
              return (
                <OptionIcon
                  key={option}
                  toggleValue={JSON.parse(car.click?.options || '{}')[option] == ClickState.LOCK}
                  isMissing={isMissing}
                  isEnabled={true}
                  option={option}
                />
              );
            })}
          </Box>
        ) : (
          <></>
        )}
      </BoxFlexColumn>
    </CarTableCellStyled>
  );
});
