import React, { useContext } from 'react';
import { Link } from '@mui/material';
import { styled } from '@mui/system';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { largeNumberFormatter } from '@japieglobal/shared/src/utils';
import { UserContext } from '@japieglobal/shared/src/user-context';
import { ColumnType } from './car-compare-columns';
import { CarWindowPriceCell } from './cells/car-compare-price-cell';
import { CarWindowDealerImageCell } from './cells/car-compare-dealer-image-cell';
import { CarWindowDeltaCell } from './cells/car-compare-delta-cell';
import { ClickLink } from './cells/car-compare-click-link';
import { CarWindowEquipmentCell } from './cells/car-compare-equipment/car-compare-equipment-cell';
import { CarWindowBanCell } from './cells/car-compare-ban-cell';
import { CarWindowImageCell } from './cells/car-compare-image-cell';
import { CarWindowOptionsCell } from './cells/car-compare-options-cell/car-compare-options-cell';
import { BooleanAppSearchParam, CustomIcon, useAppSearchParams } from '@japieglobal/shared/src/components';

const FlagImage = styled('img')({
  cursor: 'pointer',
  width: '17px',
  transform: 'scale(1)',
  transition: 'all 0.15s',
});

interface CarWindowTableCellsProps {
  car: CarResult;
  ownCar: CarResult;
  removeRanking: () => void;
  columnType: ColumnType;
  clickName?: BooleanAppSearchParam;
  value: any;
  taxationView: boolean;
  ownDealer: boolean;
}

export const CarWindowTableCells = ({
  car,
  ownCar,
  removeRanking,
  columnType,
  clickName,
  value,
  taxationView,
  ownDealer,
}: CarWindowTableCellsProps) => {
  const [searchParams, setSearchParams] = useAppSearchParams();

  const { user } = useContext(UserContext);
  return (
    <>
      {columnType === 'image' && <CarWindowImageCell ownCar={ownCar} car={car} taxationView={taxationView} />}
      {columnType == 'price' && <CarWindowPriceCell car={car} removeRanking={removeRanking} />}
      {columnType == 'logo' && <CarWindowDealerImageCell car={car} ownDealer={ownDealer} />}
      {columnType == 'delta' && <CarWindowDeltaCell car={car} />}
      {columnType === 'click' && clickName && (
        <ClickLink searchParamKey={clickName}>
          <span>{value}</span>
        </ClickLink>
      )}
      {columnType === 'mileage' && <>{largeNumberFormatter(user, value)}</>}
      {columnType === 'equipment' && (
        <CarWindowEquipmentCell ownCar={ownCar} car={car} isOfficialMake={!!ownCar.equipment?.official_make} />
      )}
      {columnType === 'options' && <CarWindowOptionsCell car={car} ownCar={ownCar} order={ownCar.options_order} />}
      {columnType === 'redirect' && (!taxationView || ownCar.id !== car.id) && (
        <Link href={car.url} target="_blank">
          <CustomIcon iconKey="redirect" viewBox="0 0 24 15" color="primary" />
        </Link>
      )}
      {columnType === 'flag' && car.stat?.local_car && (
        <FlagImage
          src="/images/flags/NL.gif"
          title="niet-import"
          onClick={() => setSearchParams({ click_stat_local_car: !searchParams.click_stat_local_car })}
        />
      )}
      {columnType === 'trash' && (
        <CarWindowBanCell car={car} ownCar={ownCar} ownDealer={ownDealer} taxationView={taxationView} />
      )}
      {columnType === 'basic' && <>{value}</>}
    </>
  );
};
