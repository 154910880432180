import { Link, styled } from '@mui/material';
import React from 'react';
import { useCarWindowStyles } from '../car-compare-styles';
import { AppSearchParams, BooleanAppSearchParam, useAppSearchParams } from '@japieglobal/shared/src/components';

const ClickLinkStyled = styled(Link)({
  color: 'rgba(0, 0, 0, 0.87)',
  padding: '2px 3px',
  borderRadius: '3px',
});

interface ClickLinkProps {
  children: React.ReactElement | string;
  searchParamKey: BooleanAppSearchParam;
}

export const ClickLink = ({ children, searchParamKey }: ClickLinkProps) => {
  const styles = useCarWindowStyles();
  const [searchParams, setSearchParams] = useAppSearchParams();
  const value = searchParams[searchParamKey];
  const getClassName = () => (searchParams[searchParamKey] === true ? styles.activeLink : '');

  return (
    <ClickLinkStyled
      className={getClassName()}
      href="#"
      onClick={(event: { preventDefault: () => void }) => {
        event.preventDefault();
        setSearchParams({ [searchParamKey]: !value } as unknown as AppSearchParams); // TODO: fix when
      }}
    >
      {children}
    </ClickLinkStyled>
  );
};
