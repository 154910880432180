import {
  BaseTable,
  RenderCellDefParams,
  TableColDef,
  Tooltip,
  useAppSearchParams,
} from '@japieglobal/shared/src/components';
import { settings } from '@japieglobal/shared/src/settings';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { IconButton } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TaxationsColumns, TaxationsColumnsValues } from './taxations-table-columns';
import { GridSortModel } from '@mui/x-data-grid';
import { SavedTaxation } from '@japieglobal/shared/src/api';

interface TaxationsTableProps {
  taxations: SavedTaxation[];
  showRemove?: boolean;
  showTaxationLink?: boolean;
  totalCars?: number;
  hoverTable?: boolean;
  pageIndex?: number;
  sortModel?: GridSortModel;
  isLoading?: boolean;
  setRowsToDelete?: (rowIds: number[]) => void;
}

export const TaxationsTable = React.memo(
  ({
    taxations,
    showTaxationLink,
    totalCars,
    hoverTable = false,
    pageIndex,
    sortModel,
    setRowsToDelete,
    isLoading,
  }: TaxationsTableProps) => {
    const { t } = useTranslation();
    const [, setAppSearchParams] = useAppSearchParams();

    const updatePageIndex = (pageIndex: number) => {
      setAppSearchParams({ page_index: pageIndex });
    };

    const handleSortModelChange = (sortModel: GridSortModel) => {
      if (sortModel.length) {
        const field = sortModel[0].field;
        const sortOrder = sortModel[0].sort === 'asc' ? field : `-${field}`;
        setAppSearchParams({ order: sortOrder });
      } else {
        setAppSearchParams({ order: '' });
      }
    };

    const buildUrl = (row: SavedTaxation) => {
      const url = new URL(row['taxation_url']);
      return url.pathname + url.search;
    };

      const showLicensePlate = settings().showLicensePlate;

    const taxationColumns = hoverTable ? TaxationsColumnsValues : TaxationsColumns;

    const columns: TableColDef<SavedTaxation>[] = [
      ...taxationColumns.map(
        ({ field, headerName, type, flex, columnType }) =>
          ({
            field: field,
            headerName: t(headerName),
            headerDescription: '',
            type: type,
            flex: !hoverTable && flex,
            width: hoverTable && flex * 40,
            align: 'center',

            cellClassName: (params) => {
              return field === 'description' && params.value && (params.value as string).length >= 70
                ? 'multipleLines itemsAtStart'
                : 'multipleLines';
            },

            renderCell: (params: RenderCellDefParams<SavedTaxation>) =>
              params.value && (
                <>
                  {columnType === 'runTaxation' && showTaxationLink && (
                    <IconButton href={buildUrl(params.row)}>
                      <PlayArrowIcon color="primary" />
                    </IconButton>
                  )}
                  {columnType === 'date' && <>{new Date(params.value).toLocaleString()}</>}
                  {columnType === 'value' && <>{params.value}</>}
                  {columnType === 'hover' && (
                    <Tooltip key={params.row.id} onHoverContent={params.value} placement={'bottom-start'}>
                      {params.value}
                    </Tooltip>
                  )}
                </>
              ),
          }) as TableColDef<SavedTaxation>,
      ),
    ];

    return (
      <BaseTable
        isLoading={isLoading}
        rows={taxations}
        columns={columns}
        getRowId={(row) => row.id}
        headerHeight={45}
        rowHeight={70}
        exportToPdf={false}
        useStripedRows={false}
        columnVisibility={{ license_plate: showLicensePlate }}
        hideFooter={hoverTable}
        paginationModel={{
          pageSize: 25,
          pageSizeOptions: [25],
          paginationMode: 'server',
          rowCount: totalCars,
          onPaginationModelChange: (model) => updatePageIndex?.(model.page),
          pageIndex: pageIndex,
        }}
        sortingModel={{
          handleSortModelChange: handleSortModelChange,
          sortModel: sortModel,
          sortingMode: 'server',
        }}
        onRowSelectionChange={(ids) => setRowsToDelete?.(ids as number[])}
      />
    );
  },
);
