import React, { useState } from 'react';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { StringAppSearchParam, TextFilter, useAppSearchParams } from '@japieglobal/shared/src/components';
import { RdwPageContainer, StyledDiv } from './rdw-page-container';
import { getCarRdw } from '@japieglobal/shared/src/api';
import { noop } from '@japieglobal/shared/src/utils';

export const RdwGetCar = () => {
  const [{ license_plate }] = useAppSearchParams();
  const [response, setResponse] = useState<(string | number | boolean)[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const { snackbarErrorHandler } = useSnackbarErrorHandler();

  React.useEffect(() => {
    if (license_plate) {
      setIsLoading(true);
      getCarRdw(license_plate)
        .then(setResponse)
        .catch(snackbarErrorHandler)
        .finally(() => setIsLoading(false));
    }
  }, [license_plate, snackbarErrorHandler]);

  return (
    <RdwPageContainer ignoreForm headerTranslationKey="GET_PLATE" isLoading={isLoading} onSubmit={noop}>
      <TextFilter
        hideResetButton
        labelTranslationKey={'LICENSE_PLATE'}
        searchParamKey={StringAppSearchParam.LICENSE_PLATE}
      />
      {Object.entries(response || {}).map(([k, v]) => (
        <StyledDiv key={k}>
          {k}: {Array.isArray(v) ? v.join(', ') : v}
        </StyledDiv>
      ))}
    </RdwPageContainer>
  );
};
