import React from 'react';
import { CsmReport, CsmReportKey, DealerAccountHealth, SalesReportRow } from '@japieglobal/shared/src/api';
import { Menu, MenuItem, IconButton } from '@mui/material';
import { HealthCell } from './health-cell';
import { UpdateDealerAccountFuncType } from '../types';

interface HealthSelectCellProps {
  row: CsmReport | SalesReportRow;
  onDealerUpdated: UpdateDealerAccountFuncType;
}

export const HealthSelectCell = ({ onDealerUpdated, row }: HealthSelectCellProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (selectedHealth: DealerAccountHealth) => {
    onDealerUpdated({ row, key: CsmReportKey.HEALTH, value: selectedHealth });
  };

  return (
    <div>
      <IconButton
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <HealthCell value={row[CsmReportKey.HEALTH] as DealerAccountHealth} />
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {Object.values(DealerAccountHealth).map((health) => (
          <MenuItem key={health} onClick={() => handleSelect(health)}>
            <HealthCell value={health} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
