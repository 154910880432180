import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  deepClone,
  hasPermission,
  isScrapeUser,
  isSuperAdmin as isSuperAdminUser,
} from '@japieglobal/shared/src/utils';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { AdminEditType, AdminPageType, AdminQueryStateType } from '../hooks/admin-query-state';
import { isValidPhoneNumber } from '../../utils/validate-phone-number';
import { getUserById, updateUser, User, userCreate, UserPermission, UserRole } from '@japieglobal/shared/src/api';
import { NetworkError } from '@japieglobal/shared/src/api/network-error';
import { UserContext } from '@japieglobal/shared/src/user-context';
import { AdminUserForm } from './admin-user-form';

const catchDuplicateEmailError =
  (snackbarErrorMessage: (val: string) => void, t: (str: string) => string) => (err: Error) => {
    if (err instanceof NetworkError) {
      if (err.response.status === 400) {
        snackbarErrorMessage(t('ERROR_EMAIL_EXISTS_ALREADY'));
        return;
      }
    }
    throw err;
  };

interface AdminMainEditUserComponentProps {
  adminQueryState: AdminQueryStateType;
  pageType: AdminPageType;
}

export const AdminMainEditUser = React.memo(({ adminQueryState, pageType }: AdminMainEditUserComponentProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { snackbarErrorHandler, snackbarErrorMessage, snackbarSuccessMessage } = useSnackbarErrorHandler();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { user } = useContext(UserContext);
  const [dealers, setDealers] = useState<{ id: string; locations: string[] }[]>([]);

  const { userId, editType } = adminQueryState;

  const isSuperAdmin = isSuperAdminUser(user);
  const isScrape = editType === AdminEditType.SCRAPE_USER;

  const [editUser, setEditUser] = useState<Partial<User>>({});
  const title = !editUser.id ? t('ADD_NEW') : t('EDIT_USER');

  const phoneNumber = useMemo(() => editUser?.phone_number, [editUser]);
  const isScraperManager = isScrapeUser(user) && hasPermission(user, UserPermission.SCRAPE_MANAGEMENT);
  const hasMonitoringPermission = hasPermission(user, UserPermission.MONITORING);
  const permissions = useMemo(
    () =>
      editUser?.permissions
        ? editUser.permissions
        : isScraperManager || !hasMonitoringPermission
          ? []
          : [UserPermission.MONITORING],
    [editUser.permissions, isScraperManager, hasMonitoringPermission],
  );
  const enable2FA = useMemo(() => editUser?.two_factor_authentication ?? true, [editUser]);
  const quotas = useMemo(() => editUser?.quotas, [editUser]);

  const catchDuplicateEmailErrorProxy = useMemo(
    () => catchDuplicateEmailError(snackbarErrorMessage, t),
    [snackbarErrorMessage, t],
  );

  const setDealersProxy = useCallback((val: { id: string; locations: string[] }[]) => {
    setEditUser((prevState) => {
      const newResult = {
        ...prevState,
        allowed_dealers: [] as string[],
        allowed_locations: {} as Record<string, string[]>,
      };
      if (val && val.length > 0) {
        val.forEach((dealer) => {
          newResult.allowed_dealers!.push(dealer.id);
          newResult.allowed_locations![dealer.id] = dealer.locations;
        });
      }
      return newResult;
    });
  }, []);

  useEffect(() => {
    if (!userId) {
      const serviceAccount = editType === AdminEditType.SERVICE_ACCOUNTS;
      const quota = serviceAccount ? {} : { api: 250 };
      const role = isScrape ? UserRole.SCRAPE_USER : editType === AdminEditType.ADMIN ? UserRole.ADMIN : UserRole.USER;
      setEditUser((prevState) => {
        return { ...prevState, is_service_account: serviceAccount, quotas: quota, role: role };
      });
    }
  }, [userId, editType, isScrape]);

  useEffect(() => {
    if (userId) {
      setIsLoading(true);
      getUserById({ userId })
        .then((res) =>
          setEditUser({
            ...res,
            id: pageType === AdminPageType.DUPLICATE_USER ? undefined : res.id,
            first_name: pageType === AdminPageType.DUPLICATE_USER ? undefined : res.first_name,
            last_name: pageType === AdminPageType.DUPLICATE_USER ? undefined : res.last_name,
            phone_number: pageType === AdminPageType.DUPLICATE_USER ? undefined : res.phone_number,
            email: pageType === AdminPageType.DUPLICATE_USER ? undefined : res.email,
            created_by: pageType === AdminPageType.DUPLICATE_USER ? undefined : res.created_by,
            created_at: pageType === AdminPageType.DUPLICATE_USER ? undefined : res.created_at,
          }),
        )
        .catch(snackbarErrorHandler)
        .finally(() => setIsLoading(false));
    }
  }, [pageType, snackbarErrorHandler, userId]);

  const updateEditUser = <K extends keyof Partial<User>>(value: Partial<User>[K], key: K) => {
    setEditUser((prevState) => ({ ...(prevState || {}), [key]: value }));
  };

  const handleChangeQuota = (v: number | undefined) => {
    let newQuota: Record<string, number | undefined>;
    if (v) {
      newQuota = { ...quotas, ...{ api: v } };
    } else {
      newQuota = { ...quotas };
      delete newQuota.api;
    }
    updateEditUser(newQuota, 'quotas');
  };

  const phoneNumberIsValid = useMemo(() => {
    return isValidPhoneNumber(phoneNumber);
  }, [phoneNumber]);

  const handleCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    if (userId && userId?.trim().length === 0) {
      handleCancel();
    }
  }, [handleCancel, userId]);

  useEffect(() => {
    if (editUser) {
      const newDealerName = isSuperAdmin ? '' : user.dealer;
      setDealers(
        editUser.allowed_dealers
          ?.filter((entry) => {
            if (!isSuperAdmin && !isScraperManager) {
              return entry === user.dealer;
            }
            return true;
          })
          .map((value) => {
            return {
              id: value,
              locations:
                editUser.allowed_locations && editUser.allowed_locations[value]
                  ? editUser.allowed_locations[value]
                  : [],
            };
          }) ?? [{ id: newDealerName, locations: [] }],
      );
    }
  }, [editUser, isSuperAdmin, user.dealer, isScraperManager]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const userSubmit = deepClone(editUser);

    if (!phoneNumberIsValid) {
      userSubmit.phone_number = undefined;
    }

    userSubmit.permissions = userSubmit.permissions || [];
    userSubmit.notifications = userSubmit.notifications || [];
    userSubmit.allowed_dealers = [];
    userSubmit.allowed_locations = {};
    userSubmit.two_factor_authentication = enable2FA;
    if (isScrape) {
      userSubmit.role = UserRole.SCRAPE_USER;
      userSubmit.allowed_locations = { 'jp.cars': [] }; // TODO: temp workaround
    }
    if (dealers && dealers.length > 0) {
      dealers.forEach((dealer) => {
        if (dealer.id && dealer.id.trim().length > 0) {
          userSubmit.allowed_dealers!.push(dealer.id.trim());
          userSubmit.allowed_locations![dealer.id.trim()] = dealer.locations;
        }
      });
    }

    userSubmit.dealer = userSubmit.allowed_dealers[0];
    userSubmit.locations = userSubmit.allowed_locations[userSubmit.dealer];
    if (!editUser.id) {
      userSubmit.is_verified = false;
    }

    if (userSubmit && editUser.id) {
      setIsLoading(true);
      updateUser({ id: editUser.id, body: userSubmit as User })
        .then(handleCancel)
        .catch(catchDuplicateEmailErrorProxy)
        .catch(snackbarErrorHandler)
        .finally(() => setIsLoading(false));
    }
    if (userSubmit && !editUser.id) {
      setIsLoading(true);
      userCreate({ user: userSubmit as User })
        .then(() => {
          snackbarSuccessMessage(
            'Het account is succesvol aangemaakt. Er is een e-mail verstuurd naar de nieuwe gebruiker.',
          );
        })
        .then(handleCancel)
        .catch(catchDuplicateEmailErrorProxy)
        .catch(snackbarErrorHandler)
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <AdminUserForm
      title={title}
      dealers={dealers}
      setDealers={setDealersProxy}
      editUser={editUser}
      updateEditUser={updateEditUser}
      adminQueryState={adminQueryState}
      handleCancel={handleCancel}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      permissions={permissions}
      handleChangeQuota={handleChangeQuota}
    />
  );
});
