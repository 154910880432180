import { useCallback, useMemo } from 'react';
import { useQueryString } from '@japieglobal/shared/src/hooks';
import { isDeepEqual } from '@japieglobal/shared/src/utils';
import { urlParseNumber } from '../../utils/url-value-parsers';

export enum AdminQueryStateKey {
  PAGE_TYPE = 'page_type',
  USER_ID = 'user_id',
  DEALER = 'dealer',
  SELECTED = 'selected',
  PAGE = 'page',
  EDIT_TYPE = 'edit_type',
  MONTH = 'month',
  YEAR = 'year',
  PERIOD = 'period',
  DATE_FROM = 'date_from',
  DATE_TO = 'date_to',
  ACTIVITY_TYPE = 'activity_type',
}

export enum AdminActivityPeriod {
  LAST_30_DAYS = 'last_30_days',
  LAST_60_DAYS = 'last_60_days',
  CUSTOM_RANGE = 'custom_range',
}

export enum AdminEditType {
  ADMIN = 'admin',
  SERVICE_ACCOUNTS = 'service_accounts',
  SCRAPE_USER = 'scrape_user',
}

export enum AdminPageType {
  SUPER_ADMINS = 'super_admins',
  ADMINS = 'admins',
  USERS = 'users',
  DEALERS = 'dealers',
  SCRAPE_ADMIN_USERS = 'scrape_admin_users',
  KEY_USERS = 'key_users',
  SERVICE_ACCOUNTS = 'service_accounts',
  PURCHASE_ACCOUNTS = 'purchase_accounts',
  ACTIVITY_LIST = 'activity_list',
  GLOBAL_ACTIVITY_LIST = 'global_activity_list',
  DUPLICATE_USER = 'duplicate_user',
  EDIT_USER = 'edit_user',
  ADD_USER = 'add_user',
  CSM_REPORT = 'csm_report',
  SALES_REPORT = 'sales_report',
  ACTIVITY_DETAIL_LIST = 'activity_detail_list',
  ACTIVITY_PURCHASE_VIEW = 'activity_purchase_view',
  ACTIVITY_AUCTIONS_VIEW = 'activity_auctions_view',
}

export type AdminQueryStateType = ReturnType<typeof useAdminQueryState>['adminQueryState'];

const emptyArray: string[] = [];

export const useAdminQueryState = () => {
  const [userId, setUserId] = useQueryString<string | undefined>(AdminQueryStateKey.USER_ID, undefined);
  const [dealer, setDealer] = useQueryString<string | undefined>(AdminQueryStateKey.DEALER, undefined);

  const [selected, setSelected] = useQueryString<string[]>(AdminQueryStateKey.SELECTED, emptyArray);
  const [page, setPage] = useQueryString<number>(AdminQueryStateKey.PAGE, 0, false, urlParseNumber);
  const [editType, setEditType] = useQueryString<AdminEditType | undefined>(AdminQueryStateKey.EDIT_TYPE, undefined);

  const setSelectedProxy = useCallback(
    (newSelected: string[]) => {
      if (!isDeepEqual(newSelected, selected)) {
        setSelected(newSelected);
      }
    },
    [selected, setSelected],
  );

  const setPageProxy = useCallback(
    (newPage: number) => {
      if (newPage !== page) {
        setPage(newPage);
      }
    },
    [page, setPage],
  );

  const adminQueryState = useMemo(
    () => ({
      editType,
      setEditType,
      userId,
      setUserId,
      dealer,
      setDealer,
      selected,
      setSelected,
      setSelectedProxy,
      page,
      setPage,
      setPageProxy,
    }),
    [
      editType,
      page,
      selected,
      setEditType,
      setPage,
      setPageProxy,
      setSelected,
      setSelectedProxy,
      setUserId,
      userId,
      dealer,
      setDealer,
    ],
  );

  return { adminQueryState };
};
