import 'intro.js/introjs.css';
import './hints.css';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Hints, Hint } from 'intro.js-react';
import { HintsContext } from './hints-context';

export const DisplayHints = () => {
  const { hintsEnabled } = useContext(HintsContext);
  const options = { hintShowButton: false };
  const { t } = useTranslation();
  const hints: Hint[] = [
    {
      element: '.establishment',
      hint: t('ESTABLISHMENT_HINT'),
      hintPosition: 'top-right',
    },
    {
      element: '.status',
      hint: t('STATUS_HINT'),
      hintPosition: 'top-right',
    },
    {
      element: '.stockType',
      hint: t('STOCK_TYPE_HINT'),
      hintPosition: 'top-right',
    },
    {
      element: '.thumbsUpFilter',
      hint: t('THUMBS_UP_HINT'),
      hintPosition: 'top-right',
    },
    {
      element: '.thumbsDownFilter',
      hint: t('THUMBS_DOWN_HINT'),
      hintPosition: 'top-right',
    },
    {
      element: '.thumbsDownDaysFilter',
      hint: t('THUMBS_DOWN_DAYS_HINT'),
      hintPosition: 'top-right',
    },
    {
      element: '.infoButton',
      hint: t('INFO_HINT'),
      hintPosition: 'middle-middle',
    },
    {
      element: '.windowButton',
      hint: t('WINDOW_HINT'),
      hintPosition: 'middle-middle',
    },
    {
      element: '.stockDaysButton',
      hint: t('STAT_SINCE_HINT'),
      hintPosition: 'top-left',
    },
    {
      element: '.licensePlateValue',
      hint: t('LICENSE_PLATE_HINT'),
      hintPosition: 'top-middle',
    },
    {
      element: '.copyButton',
      hint: t('COPY_HINT'),
      hintPosition: 'bottom-middle',
    },
    {
      element: '.plusButton',
      hint: t('PLUS_HINT'),
      hintPosition: 'middle-middle',
    },
    {
      element: '.globeButton',
      hint: t('GLOBE_HINT'),
      hintPosition: 'middle-middle',
    },
    {
      element: '.cartButton',
      hint: t('CART_HINT'),
      hintPosition: 'middle-middle',
    },
    {
      element: '.questionMarkButton',
      hint: t('QUESTION_MARK_HINT'),
      hintPosition: 'middle-middle',
    },
    {
      element: '.bpmButton',
      hint: t('BPM_HINT'),
      hintPosition: 'middle-middle',
    },
    {
      element: '.etrButton',
      hint: `${t('ETR_HINT')} <a href="/etr-explanation" target="_blank">Video</a>`,
      hintPosition: 'middle-right',
    },
    {
      element: '.itrButton',
      hint: t('ITR_HINT'),
      hintPosition: 'top-left',
    },
    {
      element: '.aprButton',
      hint: t('APR_HINT'),
      hintPosition: 'bottom-left',
    },
    {
      element: '.clicksButton',
      hint: t('CLICKS_HINT'),
      hintPosition: 'top-left',
    },
    {
      element: '.eyeButton',
      hint: t('EYE_HINT'),
      hintPosition: 'middle-middle',
    },
    {
      element: '.notesButton',
      hint: t('NOTES_HINT'),
      hintPosition: 'middle-middle',
    },
    {
      element: '.mailButton',
      hint: t('MAIL_HINT'),
      hintPosition: 'middle-middle',
    },
    {
      element: '.filterSearch',
      hint: t('FILTER_INFO'),
      hintPosition: 'middle-right',
    },
    {
      element: '.stock_count_report',
      hint: t('STOCK_DESCRIPTION'),
      hintPosition: 'top-middle',
    },
    {
      element: '.sold_count_report',
      hint: t('SOLD_DESCRIPTION'),
      hintPosition: 'top-middle',
    },
    {
      element: '.etr_report',
      hint: t('ETR_DESCRIPTION'),
      hintPosition: 'top-middle',
    },
    {
      element: '.clicks_report',
      hint: t('CLICKS_DESCRIPTION'),
      hintPosition: 'top-middle',
    },
    {
      element: '.bmp_eur_report',
      hint: t('BMP_EUR_DESCRIPTION'),
      hintPosition: 'top-middle',
    },
    {
      element: '.bmp_percentage_report',
      hint: t('BMP_PERC_DESCRIPTION'),
      hintPosition: 'top-middle',
    },
    {
      element: '.stock_days_unsold_report',
      hint: t('STOCK_DAYS_STOCK_DESCRIPTION'),
      hintPosition: 'top-middle',
    },
    {
      element: '.stock_days_sold_report',
      hint: t('STOCK_DAYS_SOLD_DESCRIPTION'),
      hintPosition: 'top-middle',
    },
    {
      element: '.stock_days_30_perc_report',
      hint: t('DAYS_IN_STOCK_0_30_DESCRIPTION'),
      hintPosition: 'top-middle',
    },
    {
      element: '.stock_days_60_perc_report',
      hint: t('DAYS_IN_STOCK_30_60_DESCRIPTION'),
      hintPosition: 'top-middle',
    },
    {
      element: '.stock_days_90_perc_report',
      hint: t('DAYS_IN_STOCK_60_90_DESCRIPTION'),
      hintPosition: 'top-middle',
    },
    {
      element: '.stock_days_120_perc_report',
      hint: t('DAYS_IN_STOCK_90_120_DESCRIPTION'),
      hintPosition: 'top-middle',
    },
    {
      element: '.stock_days_150_perc_report',
      hint: t('DAYS_IN_STOCK_120_150_DESCRIPTION'),
      hintPosition: 'top-middle',
    },
    {
      element: '.stock_days_150_180_perc_report',
      hint: t('DAYS_IN_STOCK_150_180_DESCRIPTION'),
      hintPosition: 'top-middle',
    },
    {
      element: '.stock_days_180_plus_perc_report',
      hint: t('DAYS_IN_STOCK_180_plus_DESCRIPTION'),
      hintPosition: 'top-middle',
    },
    {
      element: '.oml_report',
      hint: t('TURNOVER_RATE_DESCRIPTION'),
      hintPosition: 'top-middle',
    },
    {
      element: '.meldingen_0_plus_perc_report',
      hint: t('NOTIFICATIONS_0_DAYS_DESCRIPTION'),
      hintPosition: 'top-middle',
    },
    {
      element: '.meldingen_30_plus_perc_report',
      hint: t('NOTIFICATIONS_30_DAYS_DESCRIPTION'),
      hintPosition: 'top-middle',
    },
    {
      element: '.hidden_report',
      hint: t('HIDDEN_CARS_DESCRIPTION'),
      hintPosition: 'top-middle',
    },
    {
      element: '.price_adjustments_eur_report',
      hint: t('PRICE_ADJUSTMENTS_EUR_DESCRIPTION'),
      hintPosition: 'top-middle',
    },
    {
      element: '.price_adjustments_perc_report',
      hint: t('PRICE_ADJUSTMENTS_PERC_DESCRIPTION'),
      hintPosition: 'top-middle',
    },
    {
      element: '.missingOptionsButton',
      hint: t('MISSING_OPTIONS_HINT'),
      hintPosition: 'top-right',
    },
    {
      element: '.bannedCarsButton',
      hint: t('BANNED_CARS_HINT'),
      hintPosition: 'top-right',
    },
    {
      element: '.supplyType',
      hint: t('SUPPLY_TYPE_HINT'),
      hintPosition: 'top-middle',
    },
    {
      element: '.messagesButton',
      hint: t('MESSAGES_BUTTON_HINT'),
      hintPosition: 'top-right',
    },
    {
      element: '.priceProposalButton',
      hint: t('PRICE_PROPOSAL_BUTTON_HINT'),
      hintPosition: 'top-right',
    },
    {
      element: '.hiddenCarsButton',
      hint: t('HIDDEN_CARS_BUTTON_HINT'),
      hintPosition: 'top-right',
    },
    {
      element: '.vvpResult',
      hint: t('VVP_RESULT_HINT'),
      hintPosition: 'top-right',
    },
    {
      element: '.tdcNetResult',
      hint: t('TDCNET_RESULT_HINT'),
      hintPosition: 'top-left',
    },
    {
      element: '.tdcGrossResult',
      hint: t('TDCGROSS_RESULT_HINT'),
      hintPosition: 'top-right',
    },
    {
      element: '.saveTaxationButton',
      hint: t('SAVE_TAXATION_BUTTON_HINT'),
      hintPosition: 'middle-middle',
    },
    {
      element: '.mailTaxationButton',
      hint: t('MAIL_TAXATION_BUTTON_HINT'),
      hintPosition: 'middle-middle',
    },
    {
      element: '.topDealers',
      hint: t('TOP_DEALERS_HINT'),
      hintPosition: 'top-middle',
    },
    {
      element: '.maturityMonthsFilter',
      hint: t('MATURITY_MONTHS_FILTER_HINT'),
      hintPosition: 'top-right',
    },
    {
      element: '.mileageAtMaturityFilter',
      hint: t('MILEAGE_AT_MATURITY_FILTER_HINT'),
      hintPosition: 'top-right',
    },
    {
      element: '.stockDays',
      hint: t('STOCK_DAYS_HINT'),
      hintPosition: 'top-right',
    },
  ];
  const marketHints: Hint[] = [
    {
      element: '.key_special_recommendations',
      hint: t('KEY_SPECIAL_HINT'),
      hintPosition: 'top-middle',
    },
    {
      element: '.stock_recommendations',
      hint: t('STOCK_HINT'),
      hintPosition: 'top-middle',
    },
    {
      element: '.sold_recommendations',
      hint: t('SOLD_HINT'),
      hintPosition: 'top-middle',
    },
    {
      element: '.stock_market_recommendations',
      hint: t('STOCK_MARKET_HINT'),
      hintPosition: 'top-middle',
    },
    {
      element: '.sold_market_recommendations',
      hint: t('SOLD_MARKET_HINT'),
      hintPosition: 'top-middle',
    },
    {
      element: '.stock_days_vrrd_recommendations',
      hint: t('STOCK_DAYS_STOCK_HINT'),
      hintPosition: 'top-middle',
    },
    {
      element: '.stock_days_vrkt_recommendations',
      hint: t('STOCK_DAYS_SOLD_HINT'),
      hintPosition: 'top-middle',
    },
    {
      element: '.price_market_recommendations',
      hint: t('PRICE_MARKET_HINT'),
      hintPosition: 'top-middle',
    },
    {
      element: '.ETR_recommendations',
      hint: t('ETR_RECOMMENDATIONS_HINT'),
      hintPosition: 'top-middle',
    },
    {
      element: '.ITR_recommendations',
      hint: t('ITR_RECOMMENDATIONS_HINT'),
      hintPosition: 'top-middle',
    },
    {
      element: '.recommendations_hover',
      hint: t('RECOMMENDATIONS_HOVER_HINT'),
      hintPosition: 'top-middle',
    },
    {
      element: '.settings_button_recommendations',
      hint: t('SETTINGS_RECOMMENDATIONS_HINT'),
      hintPosition: 'top-middle',
    },
    {
      element: '.info_button_recommendations',
      hint: t('INFO_RECOMMENDATIONS_HINT'),
      hintPosition: 'top-middle',
    },
    {
      element: '.own_stock_recommendations',
      hint: t('OWN_STOCK_RECOMMENDATIONS_HINT'),
      hintPosition: 'top-right',
    },
    {
      element: '.official_dealer_recommendations',
      hint: t('BRAND_DEALER_RECOMMENDATIONS_HINT'),
      hintPosition: 'top-right',
    },
    {
      element: '.official_dealer_button_charts',
      hint: t('OFFICIAL_DEALER_CHARTS_HINT'),
      hintPosition: 'top-right',
    },
    {
      element: '.commercial_vehicles_button_charts',
      hint: t('COMMERCIAL_VEHICLES_CHARTS_HINT'),
      hintPosition: 'top-right',
    },
    {
      element: '.advice_recommendations',
      hint: t('ADVICE_RECOMMENDATIONS_HINT'),
      hintPosition: 'top-right',
    },
  ];
  return <Hints enabled={hintsEnabled} hints={[...hints, ...marketHints]} options={options} />;
};
