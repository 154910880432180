import React from 'react';
import { RenderCellDefParams } from '@japieglobal/shared/src/components';
import { MessagesCell } from '../../shared/csm-and-sales-report/cells/messages-cell/messages-cell';
import { CsmReportKey, SalesReportRow, SuperAdminResponseType } from '@japieglobal/shared/src/api';
import { SuperAdminSelectCell } from '../../shared/csm-and-sales-report/cells/super-admin-select-cell';
import { HealthSelectCell } from '../../shared/csm-and-sales-report/cells/health-select-cell';
import { StatusSelectCell } from '../../shared/csm-and-sales-report/cells/status-select-cell';
import { CellRender, UpdateDealerAccountFuncType } from '../../shared/csm-and-sales-report/types';
import { ActionsCell } from './actions-cell';
import moment from 'moment';

interface CellContentProps {
  cellRender: CellRender;
  renderCellParams: RenderCellDefParams<SalesReportRow>;
  superAdmins: SuperAdminResponseType[];
  onDealerUpdated: UpdateDealerAccountFuncType;
  getSalesReportRows: () => void;
  setRowsIsLoading: (isLoading: boolean) => void;
}

export const CellContent = ({
  setRowsIsLoading,
  onDealerUpdated,
  getSalesReportRows,
  cellRender,
  renderCellParams,
  superAdmins,
}: CellContentProps) => (
  <>
    {cellRender === 'basic' && <>{renderCellParams.value}</>}
    {cellRender === 'comments' && (
      <MessagesCell messages={renderCellParams.value} dealerAccountName={renderCellParams.row[CsmReportKey.DEALER]} />
    )}
    {cellRender === 'superAdminSelect' && (
      <SuperAdminSelectCell
        editKey={'csm'}
        onDealerUpdated={onDealerUpdated}
        superAdmins={superAdmins}
        row={renderCellParams.row}
      />
    )}
    {cellRender === 'healthSelect' && <HealthSelectCell onDealerUpdated={onDealerUpdated} row={renderCellParams.row} />}
    {cellRender === 'statusSelect' && <StatusSelectCell onDealerUpdated={onDealerUpdated} row={renderCellParams.row} />}
    {cellRender === 'actions' && (
      <ActionsCell
        setRowsIsLoading={setRowsIsLoading}
        getSalesReportRows={getSalesReportRows}
        row={renderCellParams.row}
      />
    )}
    {cellRender === 'date' && <>{renderCellParams.value && moment(renderCellParams.value).format('DD-MM-YYYY')}</>}
  </>
);
