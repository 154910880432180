import React from 'react';
import { CarInformationResult, CarWindowResult } from '@japieglobal/shared/src/api';
import { Paper } from '@mui/material';
import { TaxationListView } from './taxation-list-view/taxation-list-view';
import { LoadingWrapper } from '@japieglobal/shared/src/components';
import { TaxationOptionsView } from './taxation-options-view';

interface TaxationMainViewProps {
  evaluateAllCars: () => void;
  evaluateCar: (country: string, pageIndex?: number, pricingTarget?: number) => void;
  windowResults: { [country: string]: CarWindowResult | undefined };
  selectedCountry: string;
  windowIsLoading: { [country: string]: boolean };
  carInformationIsFilled: boolean;
  carInformation?: CarInformationResult;
  pageIndexes: { [country: string]: number };
  setPageIndexes: (pageIndexes: { [country: string]: number }) => void;
}
export const TaxationMainView = ({
  evaluateAllCars,
  windowResults,
  selectedCountry,
  windowIsLoading,
  evaluateCar,
  carInformationIsFilled,
  carInformation,
  pageIndexes,
  setPageIndexes,
}: TaxationMainViewProps) => (
  <LoadingWrapper isLoading={windowIsLoading[selectedCountry]}>
    <Paper sx={{ marginTop: '10px', border: 1, borderColor: 'primary.main' }}>
      {carInformationIsFilled && Object.values(windowResults).every((result) => !result) ? (
        <TaxationOptionsView carInformation={carInformation} evaluateAllCars={evaluateAllCars} />
      ) : (
        <TaxationListView
          windowResult={windowResults[selectedCountry]}
          evaluateCar={(pageIndex, pricingTarget) => evaluateCar(selectedCountry, pageIndex, pricingTarget)}
          pageIndex={pageIndexes[selectedCountry]}
          setPageIndex={(pageIndex: number) => setPageIndexes({ ...pageIndexes, [selectedCountry]: pageIndex })}
        />
      )}
    </Paper>
  </LoadingWrapper>
);
