import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@japieglobal/shared/src/components';
import { useTranslation } from 'react-i18next';

export const AdminInformationTooltip = ({ information }: { information: string }) => {
  const { t } = useTranslation();

  return (
    <Tooltip onHoverContent={t(`${information}_ADMIN`)}>
      <InfoIcon sx={{ fontSize: '14px', color: '#D3D3D3' }} />
    </Tooltip>
  );
};
