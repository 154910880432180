import React, { useContext, useEffect, useState } from 'react';
import 'leaflet/dist/leaflet.css';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import L, { LatLngTuple } from 'leaflet';
import { getMap, MapResponse } from '../../../../shared/src/api';
import { settings } from '@japieglobal/shared/src/settings';
import { ToggleInput } from '@japieglobal/shared/src/components';
import { UserContext } from '@japieglobal/shared/src/user-context';
import MarkerClusterGroup from 'react-leaflet-cluster';

const position: LatLngTuple = settings().mapLatLongZoom.slice(0, 2);
const mapStyle = { height: '100vh', width: '100vw' };

const customIcon = new L.Icon({
  iconUrl: 'https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png',
  iconSize: [25, 41],
});
export const SalesMap = () => {
  const { user } = useContext(UserContext);

  const [markers, setMarkers] = useState<MapResponse[]>([]);
  const [bigDealer, setBigDealer] = useState<boolean | undefined>(undefined);
  const [customersOnly, setCustomersOnly] = useState<boolean | undefined>(undefined);
  const [currentDealerOnly, setCurrentDealerOnly] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    getMap().then((r) => {
      setMarkers(r);
    });
  }, []);

  const zoom = settings().mapLatLongZoom[2];

  const filterBigDealer = (point: MapResponse) => {
    if (bigDealer === undefined) return true;
    if (bigDealer) return point.car_count > 100;
    return point.car_count <= 100;
  };

  const filterCurrentDealer = (point: MapResponse) => {
    if (currentDealerOnly) return point.dealer_name === user?.dealer;
    return true;
  };

  const filterCustomer = (point: MapResponse) => {
    if (customersOnly === undefined) return true;
    if (customersOnly) return point.is_customer;
    return !point.is_customer;
  };

  return (
    <span>
      <ToggleInput
        toggleState={bigDealer}
        setToggleState={setBigDealer}
        label={'Big dealers only (100+)'}
        fullWidth={false}
      />
      <ToggleInput
        fullWidth={false}
        toggleState={customersOnly}
        setToggleState={setCustomersOnly}
        label={'Customers only'}
      />
      <ToggleInput
        fullWidth={false}
        toggleState={currentDealerOnly}
        setToggleState={setCurrentDealerOnly}
        disableTripleState
        label={'Current dealer only'}
      />
      <MapContainer i18nIsDynamicList center={position} zoom={zoom} style={mapStyle} maxZoom={20}>
        <TileLayer
          url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <MarkerClusterGroup>
          {markers.length &&
            markers
              .filter((m) => filterCurrentDealer(m) && filterBigDealer(m) && filterCustomer(m))
              .map((marker) => (
                <Marker
                  key={JSON.stringify({ long: marker.longitude, lat: marker.latitude })}
                  icon={customIcon}
                  position={[marker.latitude, marker.longitude]}
                >
                  <Popup>
                    {marker.dealer_name}
                    {marker.location_name}
                    {marker.car_count}
                  </Popup>
                </Marker>
              ))}
        </MarkerClusterGroup>
      </MapContainer>
    </span>
  );
};
