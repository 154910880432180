import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  BooleanAppSearchParam,
  AppSearchParamKey,
  AppSearchParamKeys,
  AppSearchParams,
  NumberAppSearchParam,
  StringAppSearchParam,
  StringListAppSearchParam,
  ObjectAppSearchParam,
} from './types';
import { useCallback, useMemo } from 'react';
import { DateAppSearchParam } from './types/date-app-search-param';

const isKeyOf = (enumObj: Record<string, string>, key: string | undefined): boolean =>
  !!key && Object.values(enumObj).includes(key);

export const useAppSearchParams = (
  searchParamKeys?: AppSearchParamKey[],
): [AppSearchParams, (filterObject: AppSearchParams, override?: boolean) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setAppSearchParams = useCallback(
    (filterObject: AppSearchParams, override?: boolean) => {
      if (Object.entries(filterObject).every((prop) => String(prop[1]) == searchParams.get(prop[0])) && !override)
        return;

      if (!override) {
        setSearchParams((prev) => {
          Object.entries(filterObject).forEach(([key, value]) => {
            if (isKeyOf(BooleanAppSearchParam, key)) {
              value !== undefined ? prev.set(key, String(value)) : prev.delete(key);
            } else if (isKeyOf(NumberAppSearchParam, key)) {
              value !== undefined ? prev.set(key, String(value)) : prev.delete(key);
            } else if (isKeyOf(StringAppSearchParam, key)) {
              value ? prev.set(key, value as string) : prev.delete(key);
            } else if (isKeyOf(StringListAppSearchParam, key)) {
              value !== undefined && (value as string[])?.length > 0
                ? prev.set(key, value.toString())
                : prev.delete(key);
            } else if (isKeyOf(DateAppSearchParam, key)) {
              value !== undefined ? prev.set(key, value as string) : prev.delete(key);
            } else if (isKeyOf(ObjectAppSearchParam, key)) {
              value !== undefined ? prev.set(key, JSON.stringify(value)) : prev.delete(key);
            }
          });
          return prev;
        });
      } else {
        setSearchParams(
          Object.fromEntries(
            Object.entries(filterObject).map((prop) => (prop[1] !== undefined ? [prop[0], String(prop[1])] : [])),
          ),
        );
      }
    },
    [searchParams, setSearchParams],
  );

  const appSearchParams = useMemo(
    () => getSearchParamsObject(searchParams, searchParamKeys),
    [searchParamKeys, searchParams],
  );

  return [appSearchParams, setAppSearchParams];
};

const getSearchParamsObject = (searchParams: URLSearchParams, searchParamKeys?: AppSearchParamKey[]) => {
  let carHeaderOptionsTemp: AppSearchParams = {};
  (searchParamKeys || Object.values(AppSearchParamKeys)).forEach((key) => {
    const value = searchParams.get(key as string);
    carHeaderOptionsTemp = {
      ...(value && { [key]: typeMapper(key, value) }),
      ...carHeaderOptionsTemp,
    };
  });
  return carHeaderOptionsTemp;
};

export const typeMapper = (key: AppSearchParamKey, value: string) => {
  if (isKeyOf(BooleanAppSearchParam, key as string)) {
    if (value === 'true') return true;
    if (value === 'false') return false;
    return undefined;
  } else if (isKeyOf(NumberAppSearchParam, key as string)) {
    return value !== undefined ? Number(value) : undefined;
  } else if (isKeyOf(StringListAppSearchParam, key as string)) {
    return value !== undefined ? value.split(',') : undefined;
  } else if (isKeyOf(DateAppSearchParam, key as string)) {
    return value !== undefined ? value : undefined;
  } else if (isKeyOf(ObjectAppSearchParam, key as string)) {
    return value !== undefined ? JSON.parse(value) : undefined;
  } else return value;
};

export const useFilterGroup = (searchParamKeys: AppSearchParamKey[]) => {
  const [filledFilterKey, setFilledFilter] = useState<AppSearchParamKey>();
  const [searchParams, setSearchParams] = useAppSearchParams();
  const [selectedSearchParams] = useAppSearchParams(searchParamKeys);

  useEffect(() => {
    const selectedSearchParamKeys1 = Object.keys(selectedSearchParams) as AppSearchParamKey[];

    if (selectedSearchParamKeys1.length > 1) {
      searchParams[filledFilterKey as AppSearchParamKey] = undefined;
      delete selectedSearchParams[filledFilterKey as AppSearchParamKey];
      const selectedSearchParamKeys = Object.keys(selectedSearchParams) as AppSearchParamKey[];
      setSearchParams(searchParams);
      setFilledFilter(selectedSearchParamKeys[0]);
    } else if (selectedSearchParamKeys1.length === 1) {
      setFilledFilter(selectedSearchParamKeys1[0]);
    }
  }, [filledFilterKey, searchParams, setSearchParams, selectedSearchParams]);
};
