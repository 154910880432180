import { getProtocolAndDomain, responseFileDownload } from '../utils';
import { instance } from './instance';


export interface ClassificationModel {
  id: number;
  model: string;
  attr: string;
  val_acc: number;
  created: string;
  updated: string;
  status: string;
  possible_values: string[];
}

export interface ClassificationResult {
  model: string;
  attr: string;
  probability: number;
  possible_values: { [k: string]: number }
  error?: string;
}

export interface TrainParams {
  model?: string;
  attr?: string;
}

export const getClassificationModels = async (params: { model?: string, page: number, size: number }): Promise<{ total: number, pages: number, items: ClassificationModel[] }> =>
  instance.get(`/photo-detector/classification_models`, { params }).then((res) => res.data);

export const predictModel = async (carInput: { model: string, attr: string, image_url: string }): Promise<ClassificationResult> =>
  instance.post(`/photo-detector/predict`, [carInput], { headers: { refresh_cache: "true" } }).then((res) => res.data?.length ? res.data[0] : {});

export const buildAnnotateImage = (image_url: string): string =>
  `${getProtocolAndDomain()}/photo-detector/annotate?image_url=${image_url}`;

export const trainModel = async (body: TrainParams): Promise<ClassificationResult> =>
  instance.post(`/photo-detector/build_train`, body).then((res) => res.data?.length ? res.data[0] : {});

export const removeModel = async (model: string, attr: string): Promise<{ sucess: boolean }> =>
  instance.delete(`/photo-detector/classification_models/${model.toUpperCase()}/${attr.toLowerCase()}`).then((res) => res.data);

export const uploadDataset = async (
  body: FormData,
  props: {
    model: string;
    attr: string;
  },
) =>
  instance.post(`/photo-detector/upload_dataset`, body, { params: props, headers: { 'Content-Type': 'multipart/form-data' } }).then(
    (res) => res.data);

export const downloadDataset = async (params: { model: string, attr: string }) =>
  instance.get(`/photo-detector/download_dataset`, { params, responseType: 'blob' }).then(responseFileDownload(`dataset-${new Date().valueOf()}`));
