import React, { useState, useEffect, useMemo } from 'react';
import { Tab, Tabs, Box, styled } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { CarWindowResult } from '@japieglobal/shared/src/api';
import { LocalStorageKeys } from '@japieglobal/shared/src/types';
import { AppSearchParamKeys, ColoredOutlinedButton, useAppSearchParams } from '@japieglobal/shared/src/components';
import { ResultCountry } from './result-other-country';
import { useTranslation } from 'react-i18next';

interface ResultOtherCountriesProps {
  windowResults: { [country: string]: CarWindowResult | undefined };
  windowIsLoading: { [country: string]: boolean };
  selectedCountry: string;
  clickDealers: { [country: string]: string | undefined };
  pageIndexes: { [country: string]: number };
}

const getGroupedCountries = (countriesToGroup: string[]) => {
  return countriesToGroup.reduce<string[][]>((groups, key, index) => {
    const groupIndex = Math.floor(index / 5);
    if (!groups[groupIndex]) {
      groups[groupIndex] = [];
    }
    groups[groupIndex].push(key);
    return groups;
  }, []);
};

const StyledGroup = styled(Tabs)({
  '& .MuiTabs-flexContainer': {
    height: '100%',
  },
});

const StyledCountry = styled(Tab)<{ isselectedcountry: string }>(({ isselectedcountry, theme }) => ({
  border: '1px groove',
  borderBottom: isselectedcountry === 'true' ? `3px solid ${theme.palette.primary.main}` : undefined,
  fontSize: 13,
  textTransform: 'none',
  opacity: 1,
  minWidth: '20%',
  maxWidth: '20%',
  color: isselectedcountry === 'true' ? theme.palette.primary.main : theme.palette.info.main,
}));

export const ResultOtherCountries = ({
  windowIsLoading,
  windowResults,
  selectedCountry,
  clickDealers,
  pageIndexes,
}: ResultOtherCountriesProps) => {
  const countryKeys = useMemo(() => Object.keys(windowResults), [windowResults]);
  const [groupedCountries, setGroupedCountries] = useState(getGroupedCountries(countryKeys));

  useEffect(() => {
    if (localStorage.getItem(LocalStorageKeys.COUNTRY_ORDER) !== null) {
      const countryOrder = JSON.parse(localStorage.getItem(LocalStorageKeys.COUNTRY_ORDER)!);
      const allowedCountries = countryOrder.filter((country) => countryKeys.includes(country));
      setGroupedCountries(getGroupedCountries(allowedCountries));
    } else {
      setGroupedCountries(getGroupedCountries(countryKeys));
    }
  }, [countryKeys]);

  const { t } = useTranslation();
  const [isExpanded, setExpanded] = useState(false);
  const [, setSearchParams] = useAppSearchParams();

  const handleTabChange = (newCountry) => {
    setSearchParams({
      [AppSearchParamKeys.COUNTRY_TAB]: newCountry,
      [AppSearchParamKeys.CLICK_DEALER]: clickDealers[newCountry],
      [AppSearchParamKeys.PAGE_INDEX]: pageIndexes[newCountry],
    });
  };

  const onDragEnd = ({ destination, source }) => {
    if (!destination) return;
    const countries = [...groupedCountries];
    const destinationRowIndex = Number(destination.droppableId);
    const sourceRowIndex = Number(source.droppableId);
    const currentRow = countries[sourceRowIndex];
    const destinationRow = countries[destinationRowIndex];

    const [selectedCountry] = currentRow.splice(source.index, 1);

    destinationRow.splice(destination.index, 0, selectedCountry);

    countries[sourceRowIndex] = currentRow;
    countries[destinationRowIndex] = destinationRow;

    const combinedCountries = countries.flat();

    setGroupedCountries(getGroupedCountries(combinedCountries));
    localStorage.setItem(LocalStorageKeys.COUNTRY_ORDER, JSON.stringify(combinedCountries));
  };

  return (
    <Box sx={{ mt: 2 }}>
      <DragDropContext onDragEnd={onDragEnd}>
        {(isExpanded ? groupedCountries : [groupedCountries[0]]).map((group, groupIndex) => (
          <Droppable key={group[0]} droppableId={`${groupIndex}`} direction="horizontal">
            {(provided) => (
              <StyledGroup value={false} ref={provided.innerRef} {...provided.droppableProps}>
                {group.map((country, index) => (
                  <Draggable
                    key={country}
                    draggableId={`id-${country}`}
                    index={index}
                    disableInteractiveElementBlocking
                  >
                    {(props) => (
                      <StyledCountry
                        ref={props.innerRef}
                        {...props.draggableProps}
                        {...props.dragHandleProps}
                        label={
                          <ResultCountry
                            error={windowResults[country]?.error}
                            isLoading={windowIsLoading[country]}
                            country={country}
                            car={windowResults[country]?.car}
                          />
                        }
                        onClick={() => {
                          handleTabChange(country);
                        }}
                        isselectedcountry={(country === selectedCountry).toString()}
                      />
                    )}
                  </Draggable>
                ))}
              </StyledGroup>
            )}
          </Droppable>
        ))}
      </DragDropContext>
      <Box mt={2} width="100%" display="flex" justifyContent="flex-end">
        {groupedCountries.length > 1 && (
          <ColoredOutlinedButton onClick={() => setExpanded(!isExpanded)}>
            {t(isExpanded ? 'SHOW_LESS' : 'SHOW_MORE')}
          </ColoredOutlinedButton>
        )}
      </Box>
    </Box>
  );
};
