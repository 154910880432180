import React, { useMemo, useState } from 'react';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ColoredOutlinedButton, SelectOption } from '@japieglobal/shared/src/components';
import { Language } from '@japieglobal/shared/src/types';

export const AuthLanguageSelect = () => {
  const { snackbarErrorHandler } = useSnackbarErrorHandler();
  const { t, i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const languageOptions = useMemo(
    () => Object.entries(Language).map(([label, value]) => ({ label: t(label), value })),
    [t],
  );
  const [selectedLanguage, setSelectedLanguage] = useState<SelectOption>({
    value: i18n.language,
    label: languageOptions.filter((l) => l.value === i18n.language)[0].label,
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (lang: SelectOption) => {
    i18n.changeLanguage(lang.value).then().catch(snackbarErrorHandler);
    setSelectedLanguage(lang);
    handleClose();
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <ColoredOutlinedButton
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <img
          alt={`${selectedLanguage.label} flag`}
          width={30}
          src={`/images/flags/${selectedLanguage.value.toUpperCase()}.png`}
        />
        &nbsp;{selectedLanguage.label}
      </ColoredOutlinedButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {languageOptions.map((lang) => (
          <MenuItem key={lang.value} onClick={() => handleSelect(lang)}>
            <img alt={`${lang.label} flag`} width={30} src={`/images/flags/${lang.value.toUpperCase()}.png`} />
            &nbsp;{lang.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
