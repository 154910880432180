import ListAltIcon from '@mui/icons-material/ListAlt';
import { Dialog, DialogContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useContext } from 'react';
import { styled } from '@mui/system';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { ColoredButtonLargeText } from '../../monitoring/results/result-table-styles';
import { UserContext } from '@japieglobal/shared/src/user-context';
import { isSuperAdmin } from '@japieglobal/shared/src/utils';

const IconButton = styled(ColoredButtonLargeText)({
  width: '35px',
  height: '30px',
  fontSize: '15px',
});

export const ResultTableTrace = ({ car }: { car?: CarResult }) => {
  const [showPopup, setShowPopup] = React.useState(false);
  const { user } = useContext(UserContext);

  return (
    isSuperAdmin(user) && (
      <>
        <IconButton disabled={!car?.trace} onClick={() => setShowPopup(true)}>
          <ListAltIcon />
        </IconButton>
        {car && (
          <Dialog onClose={() => setShowPopup(false)} aria-labelledby="trace" open={showPopup} maxWidth="lg">
            <DialogContent dividers>
              {car.trace?.rank && (
                <div>
                  <h4>Ranking Rules</h4>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Rank Target</TableCell>
                          <TableCell align="left">Protocol</TableCell>
                          <TableCell align="left">Rule</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {car.trace.rank.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell align="left">{row.rank_target}</TableCell>
                            <TableCell align="left">
                              {row.protocol && (
                                <a href={`/admin-api/protocols/${row.protocol}`}>
                                  {row.protocol_name || '<unnamed>'} (id: {row.protocol})
                                </a>
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {row.protocol && row.rule && (
                                <a href={`/admin-api/protocols/${row.protocol}/rule/${row.rule}`}>
                                  {row.rule_type} (id: {row.rule})
                                </a>
                              )}
                              {(!row.protocol || !row.rule) && <div>{row.rule_type}</div>}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
              {car.trace?.window && (
                <div>
                  <h4>Window Rules</h4>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Params</TableCell>
                          <TableCell align="left">Protocol</TableCell>
                          <TableCell align="left">Rule</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {car.trace.window.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell align="left">
                              {JSON.stringify(
                                Object.entries(row.params)
                                  .filter(([, v]) => v != null)
                                  .reduce(
                                    (previousValue, [k, v]) => ({
                                      ...previousValue,
                                      [k]: v,
                                    }),
                                    {},
                                  ),
                                null,
                                2,
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {row.protocol && (
                                <a href={`/admin-api/protocols/${row.protocol}`}>
                                  {row.protocol_name || '<unnamed>'} (id: {row.protocol})
                                </a>
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {row.protocol ? (
                                <a href={`/admin-api/protocols/${row.protocol}/rule/${row.rule}`}>
                                  {row.rule_type} (id: {row.rule})
                                </a>
                              ) : (
                                <span>{row.rule_type}</span>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
              {car.trace?.conditions && (
                <div>
                  <h4>Applied Filters</h4>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Columns</TableCell>
                          <TableCell align="left">Condition</TableCell>
                          <TableCell align="left">Window Size</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {car.trace.conditions.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell align="left">{row.column ? JSON.stringify(row.column) : ''}</TableCell>
                            <TableCell align="left">
                              {row.operator} {row.value?.toString()}
                            </TableCell>
                            <TableCell align="left">{row.window_size}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </DialogContent>
          </Dialog>
        )}
      </>
    )
  );
};
