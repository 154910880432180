import { getCurrencies } from '@japieglobal/shared/src/api';
import { StringAppSearchParam, useAppSearchParams } from '@japieglobal/shared/src/components';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { settings } from '@japieglobal/shared/src/settings';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const CurrencySelector = React.memo(function CurrencySelector() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useAppSearchParams();
  const [currency, setCurrency] = useState<string>(searchParams[StringAppSearchParam.CURRENCY] || settings().currencyCode);
  const [currencies, setCurrencies] = useState<string[]>([]);
  const { snackbarErrorHandler } = useSnackbarErrorHandler();

  useEffect(() => {
    getCurrencies().then(setCurrencies).catch(snackbarErrorHandler);
  }, [snackbarErrorHandler]);

  useEffect(() => {
    searchParams[StringAppSearchParam.CURRENCY] = currency;
    setSearchParams(searchParams);
  }, [currency, searchParams, setSearchParams]);

  return (
    <FormControl margin="normal" fullWidth size='small' style={{ margin: 0 }}>
      <InputLabel>{t('Currency')}</InputLabel>
      <Select
        value={currency}
        onChange={(e) => setCurrency(e.target.value as string)}
        name="currency"
        label={t('Currency')}
        style={{ height: 35 }}
      >
        {currencies.map((currency) => (
          <MenuItem key={currency} value={currency}>
            {currency}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
});
