import React from 'react';
import { User, UserRole } from './api';
import { noop } from './utils';

const EMPTY_USER: User = {
  permissions: [],
  notifications: [],
  locations: [],
  allowed_dealers: [],
  role: UserRole.USER,
  id: '',
  updated_at: '',
  created_at: '',
  created_by: '',
  email: '',
  phone_number: '',
  password: '',
  country: '',
  language: '',
  dealer: '',
  allowed_locations: {},
  is_key: false,
  is_service_account: false,
  is_super_admin_password_set: false,
  quotas: {},
  minimal_apr: 0,
};

interface UserContextState {
  user: User;
  setUser: (val: User | undefined) => void;
}
export const UserContext = React.createContext<UserContextState>({
  user: EMPTY_USER,
  setUser: noop,
});
