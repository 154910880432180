import React from 'react';
import { Typography, Grid2 } from '@mui/material';
import { getVideoUrl } from './videos';
import { useTranslation } from 'react-i18next';

export const VideoExplanation = ({ video }: { video: string }) => {
  const { t, i18n } = useTranslation();

  return (
    <Grid2 size={{ xs: 12, lg: 6 }}>
      <div>
        <iframe
          src={getVideoUrl(video, i18n.language)}
          title={video}
          allow="fullscreen"
          style={{ width: '100%', height: '400px', border: 'none', background: 'black' }}
        />
        <Typography sx={{ fontWeight: 'bold' }}>{t(video.toUpperCase())}</Typography>
      </div>
    </Grid2>
  );
};
