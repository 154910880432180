import { CarResult } from '../types';
import { AppSearchParamKeys, AppSearchParams } from '../components/filter/types';

export const createInternalUrlParams = (searchParams: AppSearchParams) =>
  Object.entries(searchParams)
    .map(([key, value]) => `${key}=${encodeURIComponent(String(value))}`)
    .join('&');

export const createTaxationUrl = (car: CarResult, searchParams: AppSearchParams) => {
  searchParams[AppSearchParamKeys.LICENSE_PLATE] = '';
  searchParams[AppSearchParamKeys.MILEAGE] = car.mileage;
  searchParams[AppSearchParamKeys.MAKE] = car.make;
  searchParams[AppSearchParamKeys.MODEL] = car.model;
  searchParams[AppSearchParamKeys.FUEL] = car.fuel;
  searchParams[AppSearchParamKeys.GEAR] = car.gear;
  searchParams[AppSearchParamKeys.BODY] = car.body;
  searchParams[AppSearchParamKeys.BUILD] = car.build;
  searchParams[AppSearchParamKeys.HP] = car.hp;
  searchParams[AppSearchParamKeys.MODEL_YEAR] = car.model_year;
  searchParams[AppSearchParamKeys.OPTIONS] = car.options?.split(',');
  searchParams[AppSearchParamKeys.PRICING_TARGET] = car.price?.local;

  return '/taxatie?' + createInternalUrlParams(searchParams);
};
