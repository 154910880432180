import { StringAppSearchParam, Tooltip, useAppSearchParams } from '@japieglobal/shared/src/components';
import { CarResult } from '@japieglobal/shared/src/types/car-types';
import { UserContext } from '@japieglobal/shared/src/user-context';
import { getCurrencySymbolAndPosition } from '@japieglobal/shared/src/utils';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderType } from './car-compare-columns';
import { BannedCarHeaderCell } from './header-cells/car-compare-banned-cars-header-cell';
import { CarWindowMapHeaderCell } from './header-cells/car-compare-map-header-cell';
interface CarWindowTableCellsProps {
  ownCar: CarResult;
  field: keyof CarResult;
  headerType?: HeaderType;
  headerName?: string;
  headerDescription?: string;
}

export const CarWindowHeaderCells = ({
  ownCar,
  field,
  headerType,
  headerDescription,
  headerName,
}: CarWindowTableCellsProps) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const [searchParams] = useAppSearchParams();
  const currencySymbol = getCurrencySymbolAndPosition(user, searchParams[StringAppSearchParam.CURRENCY]).symbol;

  return (
    <>
      {headerType === 'basic' && headerName && (
        <Tooltip
          onHoverContent={
            headerDescription
              ? t(headerDescription)
              : t(headerName, { currencySymbol })
          }
        >
          {t(headerName, { currencySymbol })}
        </Tooltip>
      )}
      {field === 'is_banned' && ownCar.includes_banned && <BannedCarHeaderCell />}
      {field === 'country' && <CarWindowMapHeaderCell ownCar={ownCar} />}
    </>
  );
};
