import { instance } from './instance';
import { CarResult, PricingType, TopDealer } from '../../types';
import { WindowResultErrorCode } from '../../types/window-result-error-code';
import { Pageable, PageQuery } from '../../types/pagination';
import { isJavaDomain } from '../utils';

export interface CarWindowResult {
  car?: CarResult;
  window: CarResult[];
  pagable: Pageable;
  error?: WindowResultErrorCode;
  countries?: { [country: string]: CarResult };
  available_countries?: string[];
  top_dealers?: TopDealer[];
}

export interface CarPricingQuery extends CarEquipmentQuery, CarClickQuery, PageQuery, PricingTargetQuery {
  license_plate?: string;
  make?: string;
  model?: string;
  hp?: number;
  mileage?: number;
  body?: string;
  build?: number;
  fuel?: string;
  gear?: string;
  taxationClicked?: boolean;
  scope?: string;
  country?: string;
  zip_code?: string;
  co2?: number;
  url?: string;
  build_date?: string;
  protocol_other_countries?: string;
  target_country?: string;
  enable_top_dealers?: boolean;
  location_name?: string;
  use_paid_options?: boolean;
  show_ignored?: boolean;
}

export interface CarQuery extends CarEquipmentQuery, CarClickQuery, PageQuery, PricingTargetQuery, CarQueryElements {}

export interface TinyUrlResponse {
  url: string;
}

interface PricingTargetQuery {
  pricing_target?: number;
  pricing_type?: PricingType;
}

export interface CarEquipmentQuery {
  options?: string[];
  equipment_four_doors?: boolean;
}

export interface CarClickQuery {
  click_equipment_is_dealer?: boolean;
  click_model_year?: boolean;
  click_build?: boolean;
  click_equipment_in_stock?: string;
  click_stat_local_car?: boolean;
  click_equipment_official_make?: boolean;
  click_hp?: boolean;
  click_own_supply?: boolean;
  click_options?: string;
  click_dealer?: string;
  click_location?: boolean;
  click_polygon?: boolean;
  include_banned?: boolean;
}
interface CarQueryElements {
  window_car_id?: number;
  three_thumbs_up?: boolean;
  three_thumbs_down?: boolean;
  three_thumbs_down_30?: boolean;
  three_thumbs_down_60?: boolean;
  three_thumbs_down_90?: boolean;
  hidden?: boolean;
  location_names?: string;
  supply_types?: string;
  statuses?: string;
  own_supply_car_id?: number;

  stat_since?: string;
  build?: string;
  mileage?: string;
  price_local?: string;

  model_year?: string;
  fuels?: string;
  gears?: string;
  bodies?: string;
  specials?: string;

  id?: number;
  license_plate?: string;
  show_car_messages?: boolean;
  show_unread_messages?: boolean;
  show_price_proposals?: boolean;

  hp?: string;

  rank_total?: string;
  sort_clicks?: boolean;
  sort_leads?: boolean;
  sort_apr?: boolean;
  sort_stock_days?: boolean;

  make?: string;
  models?: string;

  turnover_ext?: boolean;
  turnover_int?: boolean;

  stat_turnover_int?: string;
  stat_turnover_ext?: string;
  apr?: string;
  price_history_since?: number;
  rank_target_perc?: number;
  rank_target_discount?: number;
  missing_options?: boolean;
}

export const priceCarByCarProperties = async (props: CarPricingQuery): Promise<CarWindowResult> =>
  isJavaDomain()
    ? instance
        .post(`/api/valuate`, { car: { ...props, options: props.options?.join() }, config: {} })
        .then((res) => res.data)
    : instance.get(`/api/window`, { params: props }).then((res) => res.data);

export const carWindow = async (props: CarQuery): Promise<CarWindowResult> =>
  isJavaDomain()
    ? instance.post(`/api/existing-valuate`, props).then((res) => res.data)
    : instance.get(`/api/window`, { params: props }).then((res) => res.data);

export const getTiny = async (key: string): Promise<TinyUrlResponse> =>
  instance.get(`/api/tiny/url/${key}`).then((res) => res.data);

export const getRegions = async (props: any): Promise<string[]> =>
  instance.get('/api/window/regions', { params: props }).then((res) => res.data);
