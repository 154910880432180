import React, { useState } from 'react';
import { ConfirmationDialog, useAppSearchParams } from '@japieglobal/shared/src/components';
import { CsmReport, CsmReportKey, deleteDealerAccount, SalesReportRow } from '@japieglobal/shared/src/api';
import { useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { IconButton } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

interface ActionsCellProps {
  row: CsmReport | SalesReportRow;
  getSalesReportRows: () => void;
  setRowsIsLoading: (isLoading: boolean) => void;
}

export const ActionsCell = ({ getSalesReportRows, row, setRowsIsLoading }: ActionsCellProps) => {
  const [openDialog, setOpenDialog] = useState(false);
  const { snackbarErrorHandler, snackbarSuccessMessage } = useSnackbarErrorHandler();
  const [, setSearchParams] = useAppSearchParams();

  const handleDelete = () => {
    setRowsIsLoading(true);
    deleteDealerAccount((row as SalesReportRow).id)
      .then(() => {
        getSalesReportRows();
        snackbarSuccessMessage(`The dealer ${row[CsmReportKey.DEALER]} has been permanently delete 🥺`);
        setSearchParams({ dealer: undefined });
      })
      .catch(snackbarErrorHandler)
      .finally(() => setRowsIsLoading(false));
  };

  const closeDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <IconButton color="warning" onClick={() => setOpenDialog(true)}>
        <DeleteForeverIcon />
      </IconButton>

      {openDialog && (
        <ConfirmationDialog
          yesAction={closeDialog}
          title="Gooi gewoon weg? 🤔"
          question={`Are you sure you want to delete ${row[CsmReportKey.DEALER]}? You can not wind back this action.`}
          closeDialog={closeDialog}
          noAction={handleDelete}
          textYes="No"
          textNo="Yes"
        />
      )}
    </>
  );
};
